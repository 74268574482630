// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-about-tsx": () => import("../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-app-launch-tsx": () => import("../src/pages/app-launch.tsx" /* webpackChunkName: "component---src-pages-app-launch-tsx" */),
  "component---src-pages-award-recognition-tsx": () => import("../src/pages/award-recognition.tsx" /* webpackChunkName: "component---src-pages-award-recognition-tsx" */),
  "component---src-pages-blog-1-tsx": () => import("../src/pages/Blog1.tsx" /* webpackChunkName: "component---src-pages-blog-1-tsx" */),
  "component---src-pages-blog-2-tsx": () => import("../src/pages/Blog2.tsx" /* webpackChunkName: "component---src-pages-blog-2-tsx" */),
  "component---src-pages-blog-3-tsx": () => import("../src/pages/Blog3.tsx" /* webpackChunkName: "component---src-pages-blog-3-tsx" */),
  "component---src-pages-blog-4-tsx": () => import("../src/pages/Blog4.tsx" /* webpackChunkName: "component---src-pages-blog-4-tsx" */),
  "component---src-pages-blog-5-tsx": () => import("../src/pages/Blog5.tsx" /* webpackChunkName: "component---src-pages-blog-5-tsx" */),
  "component---src-pages-blogs-and-vlogs-tsx": () => import("../src/pages/blogs-and-vlogs.tsx" /* webpackChunkName: "component---src-pages-blogs-and-vlogs-tsx" */),
  "component---src-pages-business-enquiry-tsx": () => import("../src/pages/business-enquiry.tsx" /* webpackChunkName: "component---src-pages-business-enquiry-tsx" */),
  "component---src-pages-client-testimonials-tsx": () => import("../src/pages/client-testimonials.tsx" /* webpackChunkName: "component---src-pages-client-testimonials-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-cookie-policy-tsx": () => import("../src/pages/cookie-policy.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-tsx" */),
  "component---src-pages-corporate-social-responsibility-tsx": () => import("../src/pages/corporate-social-responsibility.tsx" /* webpackChunkName: "component---src-pages-corporate-social-responsibility-tsx" */),
  "component---src-pages-digital-business-solutions-tsx": () => import("../src/pages/digital-business-solutions.tsx" /* webpackChunkName: "component---src-pages-digital-business-solutions-tsx" */),
  "component---src-pages-faqs-tsx": () => import("../src/pages/faqs.tsx" /* webpackChunkName: "component---src-pages-faqs-tsx" */),
  "component---src-pages-index-tsx": () => import("../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-locations-tsx": () => import("../src/pages/locations.tsx" /* webpackChunkName: "component---src-pages-locations-tsx" */),
  "component---src-pages-media-coverage-tsx": () => import("../src/pages/media-coverage.tsx" /* webpackChunkName: "component---src-pages-media-coverage-tsx" */),
  "component---src-pages-our-values-tsx": () => import("../src/pages/our-values.tsx" /* webpackChunkName: "component---src-pages-our-values-tsx" */),
  "component---src-pages-pf-help-centre-tsx": () => import("../src/pages/PF-help-centre.tsx" /* webpackChunkName: "component---src-pages-pf-help-centre-tsx" */),
  "component---src-pages-pf-self-help-tsx": () => import("../src/pages/PF-self-help.tsx" /* webpackChunkName: "component---src-pages-pf-self-help-tsx" */),
  "component---src-pages-press-release-1-tsx": () => import("../src/pages/press-release-1.tsx" /* webpackChunkName: "component---src-pages-press-release-1-tsx" */),
  "component---src-pages-press-release-2-tsx": () => import("../src/pages/press-release-2.tsx" /* webpackChunkName: "component---src-pages-press-release-2-tsx" */),
  "component---src-pages-press-release-3-tsx": () => import("../src/pages/press-release-3.tsx" /* webpackChunkName: "component---src-pages-press-release-3-tsx" */),
  "component---src-pages-press-release-4-tsx": () => import("../src/pages/press-release-4.tsx" /* webpackChunkName: "component---src-pages-press-release-4-tsx" */),
  "component---src-pages-press-release-5-tsx": () => import("../src/pages/press-release-5.tsx" /* webpackChunkName: "component---src-pages-press-release-5-tsx" */),
  "component---src-pages-press-release-6-tsx": () => import("../src/pages/press-release-6.tsx" /* webpackChunkName: "component---src-pages-press-release-6-tsx" */),
  "component---src-pages-press-release-tsx": () => import("../src/pages/press-release.tsx" /* webpackChunkName: "component---src-pages-press-release-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-refer-a-friend-tsx": () => import("../src/pages/refer-a-friend.tsx" /* webpackChunkName: "component---src-pages-refer-a-friend-tsx" */),
  "component---src-pages-safe-restart-tsx": () => import("../src/pages/SafeRestart.tsx" /* webpackChunkName: "component---src-pages-safe-restart-tsx" */),
  "component---src-pages-security-tsx": () => import("../src/pages/security.tsx" /* webpackChunkName: "component---src-pages-security-tsx" */),
  "component---src-pages-staffing-expertise-tsx": () => import("../src/pages/staffing-expertise.tsx" /* webpackChunkName: "component---src-pages-staffing-expertise-tsx" */),
  "component---src-pages-submit-your-cv-tsx": () => import("../src/pages/submit-your-cv.tsx" /* webpackChunkName: "component---src-pages-submit-your-cv-tsx" */),
  "component---src-pages-videos-tsx": () => import("../src/pages/videos.tsx" /* webpackChunkName: "component---src-pages-videos-tsx" */),
  "component---src-pages-virtual-webinar-tsx": () => import("../src/pages/virtual/webinar.tsx" /* webpackChunkName: "component---src-pages-virtual-webinar-tsx" */)
}

