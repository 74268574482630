import { BlogData } from "../../services/BlogData"
import React, { createRef } from "react"
import { Row, Col } from "antd"
//@ts-ignore
import bestPracticeHandbook from "../pdf/Best Practices Handbook.pdf"
import poster1 from "../pdf/Back to Work Safely - Posters-1.pdf"
import poster2 from "../pdf/Back to Work Safely - Posters-2.pdf"
import poster3 from "../pdf/Back to Work Safely - Posters-3.pdf"
//@ts-ignore
import PageContent from "../components/PageContent/PageContent"
import { text } from "../constants"
import handbookPreviewImg from "../images/handbookPreviewImg.png";

const SafeRestart = (props) => {
  const pageData = BlogData.covidRestart;
  const poster1Ref = createRef<HTMLAnchorElement>();
  const poster2Ref = createRef<HTMLAnchorElement>();
  const poster3Ref = createRef<HTMLAnchorElement>();
  const downloadPosters = (e: any) => {
    e.preventDefault();
    poster1Ref.current?.click();
    poster2Ref.current?.click();
    poster3Ref.current?.click();
  }
  return (
    <PageContent class="px-4 px-md-6 text-align-justify" location={props.location}>
      <Row justify="center" type="flex" className="pos-relative mt-6">
        <div>
          <img
            src={pageData[0].img}
            alt="image loading"
            className="w-100 h-100"
            style={{ objectFit: "cover" }}
          />
        </div>
      </Row>
      <Row className="pt-6 pb-2 c-secondary lead">{pageData[0].author}</Row>
      <h1>
        <b>{pageData[0].title}</b>
      </h1>
      <Row
        className="fw-500 font-normal"
        style={{ opacity: ".8", lineHeight: "1.38" }}
        dangerouslySetInnerHTML={{ __html: pageData[0].description }}
      ></Row>
      <Row
        className="m-2 pb-3 c-secondary lead"
        justify="center"
      >
        <Row justify="center" align="middle" type="flex">
          <Col className="mr-3 mb-sm-2">
            <a
              download
              href={bestPracticeHandbook}
              className="font-h3 fw-500 pt-2 c-primary"
            >
              <span>{text.DOWNLOAD_BEST_PRACTICE}</span>
            </a>
          </Col>
          <Col>
            <a
              download
              href={bestPracticeHandbook}
            >
              <img 
                src={handbookPreviewImg}
                alt="Best Practiece Preview Image"
                className="w-8 h-8"
              />
            </a>
          </Col>
        </Row>
        <Row justify="center" type="flex">
          <a
            className="font-h3 fw-500 pt-2 c-primary"
            onClick={downloadPosters}
          >
          {text.DOWNLOAD_POSTERS}
          </a>
          <div className="d-none">
            <a download="poster1.pdf" href={poster1} ref={poster1Ref}></a>
            <a download="poster2.pdf" href={poster2} ref={poster2Ref}></a>
            <a download="poster3.pdf" href={poster3} ref={poster3Ref}></a>
          </div>
        </Row>
      </Row>
      <Row
        className="fw-500 font-normal"
        style={{ opacity: ".8", lineHeight: "1.38" }}
        dangerouslySetInnerHTML={{ __html: pageData[0].contactDescription }}
      ></Row>
    </PageContent>
  )
}

export default SafeRestart
