import React from "react"
import { PageContentFullWidth } from "../components/PageContent/PageContent"
import { socialResponsibilty } from "../../services/SocialResponsibiltyData"
import { Row } from "antd"

const renderSocialResponsibilityContent = (socialDataContent: any) => {
  return (
    <React.Fragment>
      <div className="pb-9">
        {socialDataContent &&
          socialDataContent.map((data, index) => {
            return (
              <div key={index} className="p-3">
                <div className="font-h2 fw-500 c-primary">{data.subTitle}</div>
                <h4 className="font-h5">{data.description}</h4>
                <p className="font-normal fw-100">{data.content}</p>
              </div>
            )
          })}
      </div>
    </React.Fragment>
  )
}
const SocialResponsibilty = (props) => {
  return (
    <PageContentFullWidth location={props.location}>
      {socialResponsibilty ? (
        <Row className="d-flex flex-column m-6 text-align-center">
          <h1 className="font-h1 fw-600">{socialResponsibilty.title}</h1>
          <div>
            {renderSocialResponsibilityContent(
              socialResponsibilty.responsibilityData
            )}
          </div>
        </Row>
      ) : (
        <></>
      )}
    </PageContentFullWidth>
  )
}

export default SocialResponsibilty
