import React, { Fragment } from "react"
import RestartImg from "../../images/safely-back.jpg"
import { Row } from "antd"
import { Link } from "gatsby"

const ASafeReturnToPhysicalWorkPlace = () => {
  return (
    <Fragment>
      <Row
        type="flex"
        align="middle"
        justify="center"
        className="pos-relative mx-6 mt-4"
      >
        <div className="w-100 h-11">
          <img
            src={RestartImg}
            alt="A Safe Return To Physical Workplace"
            style={{ objectFit: "cover" }}
            className="w-100 h-11"
          />
          {/* <span
            style={{
              height: "100%",
              width: "100%",
              top: "0",
              left: "0",
              opacity: "0.4",
              mixBlendMode: "multiply",
            }}
            className="pos-absolute bg-primary"
          ></span> */}
        </div>
        <div
          style={{ bottom: "3rem" }}
          className="font-h2 fw-600 pos-absolute c-white p-4"
        >
          <Link to="/SafeRestart">
            <h3 className="c-white fw-600 text-align-center">
              Safely back to work in the new normal
            </h3>
            <h4 className="c-white fw-600 text-align-center">Read More</h4>
          </Link>
        </div>
      </Row>
    </Fragment>
  )
}

export default ASafeReturnToPhysicalWorkPlace
