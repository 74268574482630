import React from "react"
import ourValueData from "../../services/ourValue"
import { Row, Col, Card } from "antd"
import PageContent from "../components/PageContent/PageContent"
import "./style.less"

function ourValue(props) {
  return (
    <PageContent class="py-5" location={props.location}>
      <Row type="flex" justify="center" className="heading-3">
        Our Values
      </Row>
      <Row
        type="flex"
        justify="center"
        className="font-h6 fw-600 pt-3 pb-4 text-align-center"
      >
        At Adecco we are guided by our values for all our stakeholders
      </Row>
      <Row type="flex" justify="center" className="px-md-0 px-6">
        {ourValueData &&
          ourValueData.map(data => {
            return (
              <Col span={24} lg={8} className="pr-md-4 pr-0 pb-4 d-flex">
                <Card className="our-value-card w-100 c-white">
                  <Row type="flex" justify="center" className="pb-2">
                    <img src={data.img} alt="image coming soon" />
                  </Row>
                  <Row
                    type="flex"
                    justify="center"
                    className="text-align-center pb-2 font-h5"
                  >
                    <b>{data.title}</b>
                  </Row>
                  <Row
                    type="flex"
                    justify="center"
                    className="text-align-center px-4 lead"
                  >
                    {data.content}
                  </Row>
                </Card>
              </Col>
            )
          })}
      </Row>
    </PageContent>
  )
}

export default ourValue
