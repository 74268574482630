import { message } from "antd"

//utility function for checking for errors in the form
export const checkError = (props: { formFields: Array<String>; form }) => {
  var errorInData = false
  var fieldCount = 0
  const { isFieldTouched, getFieldValue, getFieldError } = props.form
  props.formFields.map((fieldName, index) => {
    if (isFieldTouched(fieldName) && getFieldError(fieldName) !== undefined) {
      errorInData = true
    }
    if (getFieldValue(fieldName) !== undefined) {
      ++fieldCount
    }
  })
  return { errorInData, fieldCount }
}

export const disableSubmitCheck = (
  errorInData: boolean,
  emptyForm: boolean,
  agreement: boolean
) => {
  var disableSubmitStatus = false
  if (errorInData || agreement === false || emptyForm === true) {
    disableSubmitStatus = true
  } else if (errorInData && agreement === true) {
    disableSubmitStatus = true
  } else if (!emptyForm && !errorInData && agreement === true) {
    disableSubmitStatus = false
  }
  return disableSubmitStatus
}

export const onlyNumValue = e => {
  var inputValue = e.key
  var reg = /^\d+$/
  if (!reg.test(inputValue)) {
    return e.preventDefault()
  }
}
export const checkMaxDigitLimit = (rule, value, callback, countryCode) => {
  if (countryCode === "India:+91" && value.length !== 10) {
    callback("Invalid mobile number!")
  } else if (value.length > 15) {
    callback("Invalid mobile number!")
  } else {
    callback()
  }
}

export const validateFileType = (fileExtension: string) => {
  if (!["pdf", "doc", "docx"].includes(fileExtension)) {
    message.error(
      "Invalid File content, Please select a .pdf, .doc, .docx file only"
    )
    return false
  }
  return true
}

export const validateFileSize = (file: any) => {
  if (file.size > 1000000) {
    message.error(
      "File Size can't be greater than 1 MB, Please choose a valid File"
    )
    return false
  }
  return true
}

export const characterValidation = /^([a-zA-Z]+\s?)*$/
export const phoneNumberValidation = /^((?!(0))[0-9]*)$/
export const validationRegex = /^[^\s]+(\s?[^\s]+)*$/
export const jobTitleValidation = /^([a-zA-Z]+\s?)*$/
export const emailValidation = /^[\w\.\_]+@[a-zA-Z]{2,100}\.[a-zA-Z]{2,3}$/

export const countryCodeData = [
  { country: "India", countryCode: "+91" },
  { country: "Antilles-Guyanea", countryCode: "+599" },
  { country: "Canada", countryCode: "+1" },
  { country: "Ecuador", countryCode: "+593" },
  { country: "Puerto Rico", countryCode: "+1" },
  { country: "Venezuela", countryCode: "+58" },
  { country: "Argentina", countryCode: "+54" },
  { country: "Chile", countryCode: "+56" },
  { country: "Mexico", countryCode: "+52" },
  { country: "United States", countryCode: "+1" },
  { country: "Brazil", countryCode: "+55" },
  { country: "Colombia", countryCode: "+57" },
  { country: "Peru", countryCode: "+51" },
  { country: "Uruguay", countryCode: "+598" },
  { country: "Austria", countryCode: "+43" },
  { country: "Czech Republic", countryCode: "+420" },
  { country: "Finland", countryCode: "+358" },
  { country: "Greece", countryCode: "+30" },
  { country: "Italy", countryCode: "+39" },
  { country: "Norway", countryCode: "+47" },
  { country: "Romania", countryCode: "+40" },
  { country: "Slovenia", countryCode: "+386" },
  { country: "Switzerland", countryCode: "+41" },
  { country: "Belgium", countryCode: "+32" },
  { country: "Croatia", countryCode: "+385" },
  { country: "France", countryCode: "+33" },
  { country: "Hungary", countryCode: "+36" },
  { country: "Luxembourg", countryCode: "+352" },
  { country: "Poland", countryCode: "+48" },
  { country: "Serbia", countryCode: "+381" },
  { country: "Spain", countryCode: "+34" },
  { country: "Turkey", countryCode: "+90" },
  { country: "Bulgaria", countryCode: "+359" },
  { country: "Denmark", countryCode: "+45" },
  { country: "Germany", countryCode: "+49" },
  { country: "Ireland", countryCode: "+353" },
  { country: "Netherlands", countryCode: "+31" },
  { country: "Portugal", countryCode: "+351" },
  { country: "Slovakia", countryCode: "+421" },
  { country: "Sweden", countryCode: "+46" },
  { country: "United Kingdom", countryCode: "+44" },
  { country: "Israel", countryCode: "+972" },
  { country: "Tunisia", countryCode: "+216" },
  { country: "Mauritius", countryCode: "+230" },
  { country: "Morocco", countryCode: "+212" },
  { country: "Australia", countryCode: "+61" },
  { country: "Malaysia", countryCode: "+60" },
  { country: "Taiwan", countryCode: "+886" },
  { country: "China", countryCode: "+86" },
  { country: "Japan", countryCode: "+81" },
  { country: "New Zealand", countryCode: "+64" },
  { country: "Thailand", countryCode: "+66" },
  { country: "Hong Kong", countryCode: "+852" },
  { country: "Korea", countryCode: "+82" },
  { country: "Singapore", countryCode: "+65" },
  { country: "Vietnam", countryCode: "+84" },
  { country: "Bahrain", countryCode: "+973" },
  { country: "Egypt", countryCode: "+20" },
  { country: "Iran", countryCode: "+98" },
  { country: "Iraq", countryCode: "+964" },
  { country: "Isreal", countryCode: "+972" },
  { country: "Jordan", countryCode: "+962" },
  { country: "Kuwait", countryCode: "+965" },
  { country: "Lebanon", countryCode: "+961" },
  { country: "Oman", countryCode: "+968" },
  { country: "Palestinian Territory", countryCode: "+970" },
  { country: "Qatar", countryCode: "+974" },
  { country: "Saudi Arabia", countryCode: "+966" },
  { country: "Syria", countryCode: "+963" },
  { country: "Turkey", countryCode: "+90" },
  { country: "United Arab Emirates", countryCode: "+971" },
  { country: "Yemen", countryCode: "+967" },
]

export const industryData = [
  "Agriculture, Dairy, Chemicals & Pharma",
  "Automobiles & Subsidiaries",
  "Education, Government & PSU",
  "Engineering & Manufacturing",
  "Financial / Legal",
  "Garments & Fashion",
  "Healthcare",
  "Hospitality & Aviation",
  "Infrastructure & Construction",
  "IT/ITES/BPO/KPO",
  "Logistics/ Supply Chain/ Transportation/ Shipping",
  "Media & Entertainment",
  "Mining, Metals, Oil, Gas & Energy",
  "Retail, FMCG, FMCD, Services & Others",
  "Telecom & Subsidiaries",
]
