import mediaCoverage1 from "../src/images/MediaCoverage/MediaCoverage1.jpg";
import mediaCoverage2 from "../src/images/MediaCoverage/MediaCoverage2.jpg"
import mediaCoverage3 from "../src/images/MediaCoverage/MediaCoverage3.jpg"
import mediaCoverage4 from "../src/images/MediaCoverage/MediaCoverage4.jpg"
import mediaCoverage5 from "../src/images/MediaCoverage/MediaCoverage5.jpg"
import mediaCoverage6 from "../src/images/MediaCoverage/MediaCoverage6.jpg"
import mediaCoverage7 from "../src/images/MediaCoverage/MediaCoverage7.jpg"
import mediaCoverage8 from "../src/images/MediaCoverage/MediaCoverage8.jpg"
import mediaCoverage10 from "../src/images/MediaCoverage/MediaCoverage10.jpg"


export const MediaCoverageData = [
  {
    img: mediaCoverage1,
    date: "Feb 18, 2020",
    type: "blog",
    title: "This company is offering two unique programmes that can make you a CEO for a month",
    description: "The Adecco Group is offering two unique programmes that can advance your career like no other. Check out the details of the CEO for One Month, and the Win4Youth programmes along with how to apply.",
    link: "https://www.indiatoday.in/education-today/news/story/this-company-is-offering-two-unique-programmes-that-can-make-you-a-ceo-for-a-month-1647627-2020-02-18"
  },
  {
    img: mediaCoverage2,
    date: "Jan 25, 2020",
    type: "blog",
    title: "Role of minorities, women critical for India to attract talent",
    description:
      "India’s poor ability to attract and retain talent is its greatest challenge even as the country jumped eight places.",
    link:
      "https://www.financialexpress.com/economy/role-of-minorities-women-critical-for-india-to-attract-talent-says-global-talent-competitiveness-index/1834750/ ",
  },
  {
    img: mediaCoverage3,
    date: "Jan 21, 2020",
    type: "blog",
    title: "Workplace trends to follow in the year 2020",
    link:
      "https://www.peoplematters.in/article/outlook2020/workplace-trends-to-follow-in-the-year-2020-24383",
  },
  {
    img: mediaCoverage4,
    date: "Dec 13, 2019 ",
    type: "blog",
    title:
      "Attention all students and recent graduates, would you like to be a CEO for One Month?",
    link:
      "https://www.edexlive.com/happening/2019/dec/13/attention-all-students-and-recent-graduates-would-you-like-to-be-a-ceo-for-a-month-9536.html",
  },
  {
    img: mediaCoverage5,
    date: "Dec 10,2019",
    type: "blog",
    title:
      "Talent acquisition strategies cannot solve the skill gap: Country Manager, Adecco",
    link:
      "https://www.peoplematters.in/article/interview/talent-acquisition-strategies-cannot-solve-the-skill-gap-country-manager-adecco-india-24013",
  },
]

export const latestMediaCoverageData = [
  {
    img: mediaCoverage6,
    date: "June 17, 2020",
    type: "blog",
    title: "Turning challenges into opportunities: How firms have golden chance to hire talented people amid job crisis",
    description: "The pandemic has had an indelible effect on the chance to hire talented people at a time when they have trouble finding or keeping jobs elsewhere.",
    link: "https://www.financialexpress.com/economy/turning-challenges-into-opportunities-how-firms-have-golden-chance-to-hire-talented-people-amid-job-crisis/1994310/?fbclid=IwAR3rm8-cMlR3zu_q2mREe9SdriL_7Qkmcc9AKOfqYNqO7q8GXG0CYZw2wss"
  },
  {
    img: mediaCoverage7,
    date: "Jul 16, 2020",
    type: "blog",
    title: "Technology To Future Proof Your Skills And Provide An Edge In The Post COVID Economy",
    description:
      "Change is the only constant in the IT sector with new disruptive technologies entering the market each year. ",
    link:
      "https://www.expresscomputer.in/guest-blogs/technology-to-future-proof-your-skills-and-provide-an-edge-in-the-post-covid-economy/60654/?fbclid=IwAR02HhZ4B1K9gFwxmmPg-3kG6zKrtU8nU6mfLahtVrxQi1q3Z0nim6gSc9o",
  },
  {
    img: mediaCoverage8,
    date: "Jul 6, 2020",
    type: "blog",
    title: "The rise in talent demand will be for specialist functions like R&D, trials and approvals",
    link:
      "https://www.expresspharma.in/covid19-updates/the-rise-in-talent-demand-will-be-for-specialist-functions-like-rd-trials-and-approvals/?fbclid=IwAR37q3BF7BzJeBxYnGQvYo0qIiCethfhtny98TxntplpXCrING_drF5wONE",
  },
  {
    img: mediaCoverage6,// TODO: Image was not there in this blog
    date: "Apr 24, 2020 ",
    type: "blog",
    title:
      "Mapping employment trends during COVID 19",
    link:
      "https://hr.economictimes.indiatimes.com/news/hrtech/talent-acquisition-and-management/mapping-employment-trends-during-covid-19/75360602",
  },
  {
    img: mediaCoverage10,
    date: "May 27,2020",
    type: "blog",
    title:
      "Four recruitment firms join hands to help India Inc return to work",
    link:
      "https://timesofindia.indiatimes.com/business/india-business/four-recruitment-firms-join-hands-to-help-india-inc-return-to-work/articleshow/76032150.cms?utm_source=whatsapp&utm_medium=social&utm_campaign=TOIMobile",
  },
]

