import BlogDetails from "../components/BlogDetails/BlogDetails"
import { BlogData } from "../../services/BlogData";
import React from "react";

const Blog1 = () => {
    return(
        <BlogDetails pageData={BlogData.blog3}/>
    )
}

export default Blog1