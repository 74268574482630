import React, { useState } from "react"
import { Row, Col } from "antd"
import HeaderComponent from "../components/Header/Header"
import StickyBar from "../components/StickyBar/StickyBar"
import { mediaData } from "../../services/MediaData"
import MediaCardLayout from "../components/MediaCardLayout/MediaCardLayout"
import VideoModal from "../components/VideoModal/VideoModal"
import PageContent from "../components/PageContent/PageContent"

const MediaCoverage = (props) => { 
  const [showVideoModal, changeVisibilityModal] = useState(false)
  const [selectedItem, changeselectedItem]: any = useState({})

  const onItemClick = (item: any) => {
    changeselectedItem(item)
    if (item.type === "video") {
      changeVisibilityModal(true)
    } else {
      window.open(item.link);
    }
  }
  return (
    <React.Fragment>
      {showVideoModal && (
        <VideoModal
          onCloseModal={() => changeVisibilityModal(false)}
          data={selectedItem}
        />
      )}
      <PageContent location={props.location}>
      <Col>
        <Row className="py-5 text-align-center heading-3">Videos</Row>
        <MediaCardLayout data={mediaData} onItemClick={onItemClick} />
      </Col>
      </PageContent>
    </React.Fragment>
  )
}

export default MediaCoverage;
