import React from 'react'
import MobileLaunchScreen from '../components/MobileLaunchScreen/MobileLaunchScreen'
import { PageContentFullWidth } from '../components/PageContent/PageContent'

function test() {
  return (
    // <PageContentFullWidth>
    //   <MobileLaunchScreen />
    // </PageContentFullWidth>
    <></>
  )
}

export default test
