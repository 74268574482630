import React, { Fragment } from "react";
import {  Col, Row } from "antd"
import { Link } from "gatsby"
import digitalSolutionIcon from "../../images/digital-solution.svg";
import AvatarIcon from "../../images/pro-avatar-icon.png"
import generalStaffingIcon from "../../images/general-staffing.svg"
import professionalStaffingIcon from "../../images/professional-staffing.svg"
import outsourcingIcon from "../../images/outsourcing.svg"
import lhhIcon from "../../images/lhh.svg"
import talentDevelopmentIcon from "../../images/talent-development.svg"
import mspRpoIcon from "../../images/msp-rpo.svg"
import permanentRecruitmentIcon from "../../images/permanent-recruitment.svg"
import hireTrainDeployIcon from "../../images/hire-train-deploy.svg"
import pontoonIcon from "../../images/pontoon.svg"
import rpoLiteIcon from "../../images/rpo-lite.svg"
import internationalEngagementIcon from "../../images/international-engagement.svg"


const SolutionsScreen = props => {
  const RouteObj = {
    Registration: {
      route: "/registration",
      comp: AvatarIcon,
    },
  }

  const Icons = {
    // avatar: AvatarIcon,
    // suitcase: SuitcaseIcon,
    generalStaffing: generalStaffingIcon,
    professionalStaffing: professionalStaffingIcon,
    outsourcing: outsourcingIcon,
    lhh: lhhIcon,
    talentDevelopment: talentDevelopmentIcon,
    mspRpo: mspRpoIcon,
    permanentRecruitment: permanentRecruitmentIcon,
    hireTrainDeploy: hireTrainDeployIcon,
    pontoon: pontoonIcon,
    rpoLite: rpoLiteIcon,
    digitalSolution: digitalSolutionIcon,
    internationalEngagement: internationalEngagementIcon,
  }
  const pageData = props.pageData
  const cardHeader = (title: string, image: string) => (
    <Fragment>
      <div className="title-img">
        <img src={Icons[image]} />
      </div>
      <span>{title}</span>
    </Fragment>
  )

  return (
    <Fragment>

      <div style={{ backgroundColor: "rgb(147, 147, 147, 0.1)" }} className="py-6">
        <Row type="flex" justify="center" className="heading-3 pb-4">
          Solutions
        </Row>
        {pageData && pageData.map((item: any) => {
          if (item.component_type === "card_list") {
            return (
              <Row type="flex" justify="center" className="px-lg-normal px-2">
                {item.sub_components.map((data, index) => {
                  return (
                    <Col span={24} lg={{ span: 8 }} className="pr-lg-2 pr-0 pb-2 d-flex">
                      <Link to={data.url} state={{ activeKey: index }} className="d-flex w-100">
                      
                        <div className="solution-card c-white w-100 d-flex">
                          <div className="child w-100 px-4 py-3">
                            <Row type="flex" align="middle" className="pb-2">
                              <Col span={5}>
                                <img src={Icons[data.img_url]} alt="image coming soon" className="mw-80" /></Col>
                              <Col span={19}>
                                <span className="font-large"><b>{data.title}</b></span></Col>
                            </Row>
                            <Row type="flex" className="font-xsmall lh-medium">
                              {data.text_content}
                            </Row>
                          </div>
                        </div>
                      </Link>
                    </Col>
                   
                  )
                })}
              </Row>
            )
          }
        })}
      </div>

    </Fragment>
  )
}

export default SolutionsScreen
