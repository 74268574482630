import React, { useState } from "react"
import { Row, Avatar, Badge, Col } from "antd"

const OurTeam = ({ teamData }) => {
  const setEmailData = email => {
    return "mailto:" + email
  }
  return (
    <React.Fragment>
      <Row type="flex" justify="center">
        <h1 className="heading-3 py-2">Our Team</h1>
      </Row>
      <Row type="flex" justify="center">
        {teamData &&
          teamData.map(data => {
            return (
              <Col
                className="d-flex flex-column align-items-center px-4 pb-6"
                lg={6}
                md={12}
                span={24}
              >
                <Badge
                  count={
                    <a
                      href={data.linkedin}
                      target="_blank"
                      className="c-white text-align-center"
                    >
                      in
                    </a>
                  }
                  offset={[-20, 100]}
                  className="font-normal fw-600"
                  style={{
                    backgroundColor: "#0e76a8",
                    width: "1.7rem",
                    height: "1.7rem",
                    borderRadius: "14px",
                    padding: "0.25rem",
                  }}
                >
                  <Avatar size={110} icon={<img src={data.img} />} />
                </Badge>
                <div className="font-normal pt-2 pb-1">
                  <b>{data.name}</b>
                </div>
                <small>
                  {""}
                  <a
                    href={setEmailData(data.email)}
                    className="c-info font-small pb-2"
                  >
                    {data.email}
                  </a>
                </small>
                <div className="font-small c-secondary text-align-center">
                  {data.profession}
                </div>
              </Col>
            )
          })}
      </Row>
    </React.Fragment>
  )
}

export default OurTeam
