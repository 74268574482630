import { Row } from 'antd'
import React from 'react'
import { PressReleaseData } from '../../services/PressReleaseData'
import PageContent from '../components/PageContent/PageContent'

export default function PressRelease() {
  return (
    <PageContent class="px-4 px-md-6 text-align-justify py-5">
      <Row className="fw-500 font-normal" dangerouslySetInnerHTML={{__html: PressReleaseData[3].content}}></Row>
    </PageContent>
  )
}
