import React from "react"
import "./carouselStyle.less"
import { CustomCarouselData } from "../../../services/CustomCarouselData"

const CustomCarousel = () => {
  return (
    <div className="marquee ">
      <div
        className="banner pos-fixed px-6 pt-2"
        style={{ bottom: "0", zIndex: 100 }}
      >
        <p className="content">
          {CustomCarouselData.map((item, index) => {
            const pipe = (index !== CustomCarouselData.length-1) ? " | " : "";
            return (
              <span>
                {item.title}
                <a href={item.isEmail ? `mailto:${item.link}` : `${item.link}`}>{item.linkTitle}</a>
                {pipe}
              </span>
            )
          })}
        </p>
      </div>
    </div>
  )
}

export default CustomCarousel
