import React from "react"
import { Row, Col } from "antd"
import { PressReleaseData } from "../../services/PressReleaseData"
import PageContent from "../components/PageContent/PageContent"
import PressReleaseCardLayout from "../components/PressReleaseCard/PressReleaseCardLayout"

const PressRelease = (props) => {
  return (
    <PageContent location={props.location}>
      <Col>
        <Row className="pt-5 pb-4 text-align-center heading-3">Press Release</Row>
        <Row className="text-align-center font-h6 c-secondary">MEDIA CONTACT</Row>
        <Row className="pb-5 text-align-center font-h6 c-secondary">
          Cauvery Uthappa | Cauvery.Uthappa@adecco.com | +919845172958
        </Row>
        <PressReleaseCardLayout data={PressReleaseData} />
      </Col>
    </PageContent>
  )
}

export default PressRelease
