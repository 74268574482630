import React from "react"
import AwardData from "../../services/AwardRecognition"
import { Row, Card, Col, Button } from "antd"
import { PageContentFullWidth } from "../components/PageContent/PageContent"

const AwardRecognition = (props) => {

    const showAwards = (start: number, end: number) => {
        return (
            <div>
                {AwardData && AwardData.slice(start, end).map(data => {
                    return (
                        <Card className="m-3" style={{borderRadius: "0.625rem", boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.16)"}} key={AwardData.indexOf(data)}>
                            <Row type="flex" align="middle" justify="center">
                                <Col lg={4} md={24} className="p-md-2 text-align-center">
                                    <img src={data.img} className="mw-80"/>
                                </Col>
                                <Col lg={20} md={24}>
                                    <h2 className="font-large fw-600 pb-1">{data.title}</h2>
                                    <p className="" style={{lineHeight: "1.6", opacity: "0.8"}}>{data.content}</p>
                                </Col>
                            </Row>
                        </Card>
                    )
                })}
            </div>
        )
    }


    

    const showMoreAwards = () => {
        const showAwards = document.getElementById("showMore")
        if (showAwards && showAwards.style !== null)
            showAwards.style.display = "block"
    }

    return (
        <PageContentFullWidth class="pb-9" location={props.location}>
            <h1 className="heading-3 pt-5 text-align-center">Awards & Recognition </h1>
            <Row className="font-large px-md-9 px-5 text-align-center" style={{lineHeight: "1.7"}} type="flex" justify="center"><p>When you put everything you’ve got into being a good employer, finding the perfect someone for that perfect job, and giving young graduates and disadvantaged job seekers a leg up on the job ladder, people can’t help but notice. We’re very thankful for all the honours we’ve received over the years.</p></Row>
            <Row className="pt-2 px-md-8 px-2" type="flex" justify="center">
                {showAwards(0, 5)}
            </Row>
            <div className="pt-2 px-md-8 px-2" style={{ display: "none" }} id="showMore">
                {showAwards(5, AwardData.length)}
            </div>
            {/* TODO: remove class "d-none" if there are more awards */}
            <Row type="flex" justify="center" className="d-none">
                <Button className=" m-5 btn-white" onClick={() => showMoreAwards()} >
                    View More
                </Button>
            </Row>
        </PageContentFullWidth>
    )
}

export default AwardRecognition