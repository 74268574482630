import { ProtectYourself } from "../src/components/Security/ProtectYourself";
import { Descriptions } from "antd";

// import securityImage from "../src/images/security.svg";

export const securityData = {
    security: {
    description: "<p>Multiple fraud attempts using the Adecco name have been reported in several countries. Please be aware that NO Adecco representative will ever request payment of any kind from a candidate or an associate during a job search or a selection process.</p><br/><p>We have been informed that the ADECCO name and trademark are being used in several countries by persons who are in no way related to the Adecco Group. These persons place job adverts on general market websites using the name 'Adecco' without permission. We also have reason to believe that e-mails,letters,telephone calls and other actions taken by these persons are nothing but illegitimate attempts to obtain money and access to job seekers' personal and confidential information</p>",
    },
    protectYourself: {
        description: "<p>Before releasing any personal information, ID or bank account details during a job search process, make sure your contact is an authorised Adecco representative. You can confirm this by getting in touch with an Adecco office in your country.<br/><a href='/locations' style='color:#73C2FB'>Find the locations here.</a></p><br/><p>Beware of any unexpected e-mail, instant message, voicemail or fax that claims to be from Adecco. If you receive such a message, get in touch with an Adecco office in your country and verify that the message is legitimate.</p><br/><p>If you believe that you have been victim of such scams, we strongly recommend that you report the incident to local authorities.</p>",
    },
    contactUs: {
        description: "<p>If you would like to report an abuse of the Adecco name and trademark, please contact the Adecco office in the country where the incident occurred. If Adecco does not operate in that country, please notify:<br/><a href='mailto:compliance@adecco.com' style='color:#73C2FB'>compliance@adecco.com</a> or <a href='http://www.aceconduct.com' target='_blank' style='color:#73C2FB'>www.aceconduct.com</a>.</p><br/><p>If you are looking for a job in your country, connect with Adecco through your local <a href='/' style='color:#73C2FB'>Adecco web site</a></p>",
    }
}



 

