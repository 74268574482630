import React from "react"
import { Row, Col, Card, Avatar } from "antd"
import testimonial_image from "../../images/testimonial-image.svg"
import avatar_image from "../../images/avatar.png"
import arrow_down from "../../images/testimonial-arrow-down.svg"
import arrow_up from "../../images/testimonial-arrow-up.svg"
import inverted_commas from "../../images/inverted-comma.svg"
import "./TestimonialScreen.less"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import GoToTopBtn from "../HomePage/GoToTopBtn"

interface Testimonial_props {
  pageData: []
  showGoToTop: string
}
class TestimonialScreen extends React.Component<Testimonial_props> {
  renderArrows = () => {
    return (
      <div>
        <div
          className="my-2"
          style={{ cursor: "pointer" }}
          onClick={() => this.slider.slickPrev()}
        >
          <img src={arrow_down} className="mw-80" />
        </div>
        <div
          className="my-2"
          style={{ cursor: "pointer" }}
          onClick={() => this.slider.slickNext()}
        >
          <img src={arrow_up} className="mw-80" />
        </div>
      </div>
    )
  }

  renderCards = (card, index) => {
    return (
      <Card className="cards" key={index}>
        <Row type="flex" justify="space-between" align="middle">
          <Col md={18} span={24}>
            <Row type="flex" justify="space-between">
              <div>
                <img src={inverted_commas} className="mw-70" />
              </div>
              <div className="lead my-2 text-align-justify">
                {card.text_content}
              </div>
              <div>
                <b className="c-black">{card.name}, </b>
                <span className="c-secondary">{card.position}</span>
              </div>
            </Row>
          </Col>
          <Col span={24} md={5} className="d-flex align-items-center">
            <Avatar size={100} icon={<img src={avatar_image} />} />
          </Col>
        </Row>
      </Card>
    )
  }

  render() {
    const pageData = this.props.pageData
    const slider_settings = {
      centerMode: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      vertical: true,
      arrows: false,
      adaptiveHeight: true,
    }

    return (
      <React.Fragment>
        <Row type="flex" justify="center">
          <h1 className="heading-3">Testimonials</h1>
        </Row>
        <Row type="flex" justify="center">
          <Col span={8} className="d-none d-md-block">
            <img
              src={testimonial_image}
              alt="testimonial_image"
              className="mw-100"
            />
          </Col>
          <Col
            span={1}
            className="d-flex justify-content-center align-items-center flex-column"
            style={{ opacity: ".5" }}
          >
            {/* {this.renderArrows()} */}
          </Col>
          <Col span={20} md={12}>
            <Row type="flex" align="middle" className="py-0 my-md-9">
              <Slider ref={c => (this.slider = c)} {...slider_settings}>
                {pageData &&
                  pageData.map((card, index) => {
                    return this.renderCards(card, index)
                  })}
              </Slider>
            </Row>
          </Col>
        </Row>
        {this.props.showGoToTop === "1" ? <GoToTopBtn /> : null}
      </React.Fragment>
    )
  }
}

export default TestimonialScreen
