import { Card, Row } from 'antd'
import { Link } from 'gatsby'
import React from 'react'

export default function PressReleaseCard(props: any) {
  let styles: any = {
    height: "250px",
    width: "325px",
    overflow: "hidden",
    paddingBottom: "0px",
    display: "flex",
    alignItems: "center",
  }
  return (
    <Link to={props.data.link}>
      <Card 
        style={{
          marginRight: "10px",
          marginBottom: "10px"
        }}
        bodyStyle={{
          paddingBottom: "10px", 
          paddingTop: "10px"
        }}
        hoverable
      >
        <Row style={styles}>
          <Row type="flex" className="text-align-center">
            <span className="font-medium fw-600 pb-1">{props.data.title}</span>
            <span className="font-xxsmall pb-1 w-100 c-secondary text-align-center">{props.data.date}</span>
            <span className="font-small pb-1">{props.data.description}</span>
          </Row>
        </Row>
        <Row type="flex" justify="end"><Link to={props.data.link}>read more</Link></Row>
      </Card>
    </Link>
  )
}
