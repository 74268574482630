import { staffingEvolution } from "../../../services/staffingEvolution"
import React from "react"
import { Row, Col } from "antd"
//@ts-ignore
import bannerImage from "../../images/Virtual-Webinar-Page-banner.jpg"
import { PageContentFullWidth } from "../../components/PageContent/PageContent"
import { text } from "../../constants"
import { WrappedStaffingEvolution } from "../../components/Forms/StaffingEvolutionForm"
import "../style.less";

// Moved Outsourcing page to virtual/webinar and updated page

const StaffingEvolution = (props: { location: any }) => {
  return (
    <PageContentFullWidth class="pb-9" location={props.location}>
      <Row
        justify="center"
        type="flex"
        className="pos-relative mb-4 justify-content-center"
      >
        <img
          src={bannerImage}
          alt="image loading"
          className="w-100 h-lg-auto h-md-auto banner-image"
        />
      </Row>


      <Row type="flex" justify="space-around">
        <Col lg={11} md={22} xs={22}>
          <Row>
            <p className="font-md-h5 font-sm-h6 font-xs-h6"><b>{staffingEvolution.title}</b></p>
          </Row>
          <Row
            className="fw-500 font-normal p-2"
            dangerouslySetInnerHTML={{
              __html: staffingEvolution.description,
            }}
          ></Row>

          <Row
            type="flex"
            justify="space-around"
            className="pos-relative mb-4 align-items-center"
          >
          </Row>
        </Col>
        <Col lg={9} md={22} xs={22}>
          <h2 className="text-align-center font-small font-sm-h5 fw-600 c-primary">
            {text.REGISTER}
          </h2>
          <WrappedStaffingEvolution
            pageSource={text.STAFFING_ON_DEMAND}
          />
        </Col>
      </Row>
    </PageContentFullWidth>
  )
}

export default StaffingEvolution
