import React, { useState } from "react";
import { Modal, Button } from "antd";

const ModalComponent = (props:{title: string, isVisible: boolean, getValueModal: (value: boolean) => void, modalData: string, url?: string}) => {
    const [visibility, setVisibility] = useState(props.isVisible)
    
    const handleVisibility = () => {
        props.getValueModal(false);
    }
    return(
        <div>
        <Modal
        zIndex = {3000}
        visible={visibility as boolean}
        title={props.title}
        centered
        footer={[
            <Button key="submit" type="primary" onClick={handleVisibility}>
              OK
            </Button>,
          ]}
        closable={false}
        >
            <p className="h-10" style={{overflowY:"scroll"}} dangerouslySetInnerHTML={{__html: props.modalData}}></p>
       
          </Modal>
          </div>
    )

}

export default ModalComponent