import React from "react"
import { Row, Col, Button } from "antd"
import "./HomePageStyle.less"
import DreamJobScreenImg from "../../images/dream-job.png"

const DreamJobScreen: React.FC<{ pageData: any }> = ({ pageData }) => {
  return (
    <div className="">
      <Row
        type="flex"
        align="middle"
        justify="center"
        className="p-6 p-md-9 p-lg-0"
      >
        <Col md={24} lg={10} className="d-none d-lg-block pt-8">
          <img
            src={DreamJobScreenImg}
            alt="dream job image"
            className="mw-100"
          />
        </Col>
        <Col md={24} lg={9}>
          {pageData &&
            pageData.map((item: any) => {
              if (item.component_type === "page_header_text") {
                return (
                  <h1 className="heading-1 c-white">{item.text.content}</h1>
                )
              }
              if (item.component_type === "sub_header_text") {
                return (
                  <h2 className="heading-4 c-white ls-1">
                    {item.text.content}
                  </h2>
                )
              }
              if (item.component_type === "description_text") {
                return (
                  <h3
                    className="c-white fw-600 ls-1"
                    style={{ lineHeight: "2" }}
                  >
                    {item.text_content}
                  </h3>
                )
              }
            })}
          <Row className="pt-4">
            <Col md={24} lg={10} className="pr-xs-3">
              <Button type="default" className="w-100 fw-600 h-6" size="large">
                <a href="http://jobs.adecco.in/home/jobs" target="_blank">
                  Search for Jobs
                </a>
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default DreamJobScreen
