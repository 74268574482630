import React, { useState, useEffect } from "react"
import { Row, Col, Menu, Card } from "antd"
import DetailScreen from "./DetailScreen"
import "./StaffingExpertise.less"

const StaffingExpertiseScreen = ({ pageData, activeKey }) => {
  const [details, setDetails] = useState(pageData[0])
  const [activeKeyMenu, setActiveKeyMenu] = useState(activeKey)

  const getDetails = (details: any, index: number) => {
    setDetails(details)
    setActiveKeyMenu(index)
    window.location = details.url
  }

  useEffect(() => {
    let f = -1
    pageData.map((item, index) => {
      item.key === activeKey ? (f = index) : ""
    })
    if (f > -1) {
      getDetails(pageData[f], f)
    }
  }, [activeKey])

  let activeClass = ""

  return (
    <>
      <Row type="flex" justify="center">
        <h1 className=" pb-6 text-align-center heading-4">
          Get our staffing expertise in your speciality area
        </h1>
      </Row>
      <Card className="bg-secondary d-lg-flex d-none">
        <Row className="w-100">
          <Col span={8}>
            <Menu
              style={{ borderRadius: "0.5rem", border: "solid 1px #d5d5d5" }}
              mode="inline"
              selectedKeys={[details.key]}
            >
              {pageData.map((row: any, index: number) => (
                <React.Fragment>
                  <Menu.Item
                    {...(index === activeKeyMenu
                      ? (activeClass = "menu-item-selected")
                      : (activeClass = ""))}
                    className={`justify-content-center py-3 px-3 ${activeClass}`}
                    style={{ cursor: "pointer" }}
                    key={row.key}
                    onClick={() => getDetails(row, index)}
                  >
                    <div className="d-flex align-items-center font-medium" >
                      <div><img src={row.img} className="h-7 pr-4" /></div>
                      <div className="lh-medium">{row.title}</div>
                      </div>
                  </Menu.Item>
                  {index < pageData.length - 1 && (
                    <Menu.Divider className="ant-menu-item-divider" />
                  )}
                </React.Fragment>
              ))}
            </Menu>
          </Col>
          <Col span={16}>
            <DetailScreen details={details} />
          </Col>
        </Row>
      </Card>
      <Row type="flex" className="d-lg-none d-md-flex">
        <Col span={24} className="px-1">
          {pageData.map((row: any) => (
            <Card className="bg-secondary mb-3" key={row.key} id={row.key}>
              <DetailScreen details={row} />
            </Card>
          ))}
        </Col>
      </Row>
    </>
  )
}

export default StaffingExpertiseScreen
