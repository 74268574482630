import AnneImage from "../src/images/OurTeam/Mask Group 34@3x.png"
import ManuImage from "../src/images/OurTeam/Mask Group 35@3x.png"
import AnjaliImage from "../src/images/OurTeam/Mask Group 37@3x.png"
import SurenderImage from "../src/images/OurTeam/Mask Group 38@3x.png"
import AartiImage from "../src/images/OurTeam/Mask Group 39@3x.png"
import RoshanImage from "../src/images/OurTeam/Mask Group 40@3x.png"
import CauveryImage from "../src/images/OurTeam/Mask Group 41@3x.png"
import RameshImage from "../src/images/OurTeam/Ramesh@3x.png"
import DebabrataImage from "../src/images/OurTeam/Debabrata.png"
import VidyaSagarImage from "../src/images/OurTeam/VidyaSagar.jpg"
import SundarrajanImage from "../src/images/OurTeam/Sundarrajan.jpeg"
import AdarshImage from "../src/images/OurTeam/Adarsh.jpg"

const aboutUsTeam = [
  {
    img: VidyaSagarImage,
    name: "Vidya Sagar Gannamani",
    profession: "Chairman and Managing Director",
    email: "Vidya.Sagar@adecco.com",
    linkedin: "https://www.linkedin.com/in/vidyasagargannamani/",
  },
  {
    img: SundarrajanImage,
    name: "Sundararajan Parthasarathy",
    profession: "Country Finance Officer",
    email: "Sundararajan.Parthasarathy@adecco.com",
    linkedin: "https://www.linkedin.com/in/sundararajanp/",
  },
  {
    img: AnneImage,
    name: "Anne Soumya",
    profession: "Director HR",
    email: "Anne.Soumya@adecco.com",
    linkedin: "https://www.linkedin.com/in/anne-soumya-b226913/",
  },
  {
    img: ManuImage,
    name: "Manu Saigal",
    profession: "Director - General Staffing",
    email: "Manu.Saigal@adecco.com",
    linkedin: "https://www.linkedin.com/in/manu-saigal-098361/",
  },
  {
    img: AnjaliImage,
    name: "Anjali Vaishal",
    profession: "Managing Director – LHH",
    email: "Anjali.Vaishal@lhhindia.co.in",
    linkedin: "https://www.linkedin.com/in/anjali-vaishal-96263513/",
  },
  {
    img: SurenderImage,
    name: "Surender Dinkar",
    profession: "Director - IT",
    email: "Surender.Dinkar@adecco.com",
    linkedin: "https://www.linkedin.com/in/sdinkar/",
  },
  {
    img: RameshImage,
    name: "Ramesh Alluri Reddy",
    profession: "Director - Managed Services & Professional Services",
    email: "Ramesh.Allurireddy@adecco.com",
    linkedin: "https://www.linkedin.com/in/ramesh-alluri-reddy-8222522",
  },
  {
    img: AartiImage,
    name: "Aarthi Ganesh",
    profession: "Associate Director - IT Product, Engineering & RPO Lite",
    email: "Aarthi.Ganesh@adecco.com",
    linkedin: "https://www.linkedin.com/in/aarthi-ganesh-08280b175/",
  },
  {
    img: DebabrataImage,
    name: "Debabrata Debnath",
    profession: "Associate Director – Outsourcing",
    email: "Debabrata.Debnath@adecco.com",
    linkedin: "https://www.linkedin.com/in/debabrata-debnath-1563a24/",
  },
  {
    img: RoshanImage,
    name: "Roshan Ramapuram",
    profession: "Head of Legal & Compliance",
    email: "Roshan.Ramapuram@adecco.com",
    linkedin: "https://www.linkedin.com/in/roshanrt/",
  },
  {
    img: CauveryImage,
    name: "Cauvery Uthappa",
    profession: "Head of Marketing & Communications",
    email: "Cauvery.Uthappa@adecco.com",
    linkedin: "https://www.linkedin.com/in/cauvery-uthappa-bb40046/",
  },
  {
    img: AdarshImage,
    name: "Adarshanand Sudhakar",
    profession: "Head of Shared Services Centre",
    email: "Adarshanand.Sudhakar@adecco.com",
    linkedin: "https://www.linkedin.com/in/adarshanands/",
  },
  
]

export default aboutUsTeam
