import { HelpCenterData } from "../../services/HelpCenterData"
import React from "react"
import { Row, Col } from "antd"
//@ts-ignore
import pfPresentation from "../pdf/PF Self Help Online Portal_Final.pdf"
//@ts-ignore
import PageContent from "../components/PageContent/PageContent"
import { text } from "../constants"

const PFHelpCenter = (props) => {
  const pageData = HelpCenterData.PFHelpCenter;
  return (
    <PageContent class="px-3 px-md-5 text-align-justify" location={props.location}>
      <Row justify="center" type="flex" className="pos-relative my-6">
        <div>
          <img
            src={pageData.img}
            alt="image loading"
            className="w-100 h-100"
            style={{ objectFit: "cover" }}
          />
        </div>
      </Row>
      <Row className="text-align-center">
        <h1 className="c-primary">
          <b>{pageData.title}</b>
        </h1>
        <Row
          className="fw-500 font-normal"
          style={{ opacity: ".8", lineHeight: "1.38" }}
          dangerouslySetInnerHTML={{ __html: pageData.description }}
        ></Row>
      </Row>
      <Row className="mt-4">
        <h2>
          {pageData.contents[0].subTitle}
        </h2>
        <p>
          <ul>
            {pageData.contents[0].contentList?.map((data, index) => (
              <li key={index}>{data}</li>
            ))}
          </ul>
        </p>
        <p>
          {pageData.contents[0].footerContent}
        </p>
      </Row>
      <Row>
        <h2>
          {pageData.contents[1].subTitle}
        </h2>
        <div>
            <img
              src={pageData.contents[1].image}
              alt="image loading"
              className="w-100 h-100"
              style={{ objectFit: "cover", borderWidth: "10px", borderColor: "black" }}
            />
        </div>
      </Row>
      <Row justify="center" align="middle" className="mt-4">
        <Row justify="center" align="middle" type="flex">
          <img
              src={pageData.attachmentPreviewImage}
              alt="image loading"
              className="w-8 h-8 center ml-3 mt-2"
              style={{ objectFit: "cover", borderWidth: "10px", borderColor: "black" }}
            />
        </Row>
        <Row justify="center" align="middle" type="flex">
          <a
            download
            href={pageData.attachment}
            className="font-h6 pt-2 c-primary align-center mt-2"
          >
            <span>{text.CLICK_TO_DOWNLOAD_PF_ATTACHMENT}</span>
          </a>
        </Row>
      </Row>
    </PageContent>
  )
}

export default PFHelpCenter
