import { Row } from 'antd'
import React from 'react'
import PressReleaseCard from './PressReleaseCard'

export default function PressReleaseCardLayout(props: any) {
  return (
    <>
      <Row type="flex" justify="center" className="pt-2 c-white">
        <Row type="flex" justify="center">
          <Row>
            <PressReleaseCard data={props.data[0]} index={1} />
            <PressReleaseCard data={props.data[3]} index={2} />
          </Row>
          <Row>
            <PressReleaseCard data={props.data[1]} index={1} />
            <PressReleaseCard data={props.data[4]} index={2} />
          </Row>
          <Row>
            <PressReleaseCard data={props.data[2]} index={3} />
            <PressReleaseCard data={props.data[5]} index={4} />
          </Row>
        </Row>
      </Row>
    </>
  )
}
