export const socialResponsibilty = {
  title: "Corporate Social Responsibility",
  responsibilityData: [
    {
      subTitle: "Social Responsibility",
      description: "",
      content:
        "The Adecco Group Foundation is changing the way society thinks about human potential. We are not a grant-giving foundation, but rather a social innovation lab, incubating and accelerating new solutions in the world of work. Our vision and purpose are to ensure that people across the globe are inspired, prepared, and empowered to embrace the future of work.",
    },
    {
      subTitle: "Win4Youth",
      description: "Changing lives through sports",
      content:
        "Encourages colleagues, associates, and clients to participate in athletic activities to raise funds to support children and young people to be work-ready. Win4Youth inspires Adecco Group employees, associates, and clients to live an active and healthy life by engaging in sports. The model is simple: log your kilometers or time spent doing your favorite sports, and raise money for programmes that help young people around the world to be work-ready.",
    },
    {
      subTitle: "CEO for One Month",
      description: "Helping young talent get ready for work",
      content:
        "Empowers young people with the skills and experience necessary to join in the world of work. Many young people struggle to find a way into work because they lack the experience and skills that companies look for. Through CEO for One Month and Experience Work Day, we give young people hands-on experience to help them to get a foot on the career ladder and prepare for success.",
    },
    {
      subTitle: "Contribution to PM-CARES Fund",
      description: "",
      content:
        "At Adecco India, we are highly committed and stand together to fight against COVID-19 pandemic. In our endeavor to support the cause and make a difference to our vulnerable community, we contributed INR 1.04 crores to the honorable Prime Ministers relief fund. We are also encouraging our extended community of clients, associates and employees to step forward to do their personal best. Moreover, we will continue to work in lock-step with candidates and job seekers by leveraging technology and online tools to ensure hiring is not impacted and career aspirations affected.",
    },
    
  ],
}
