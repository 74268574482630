/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { MetaData } from "../../services/MetaData"

const SEO: React.FC<{
  location?: any
}> = ({ location }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            keywords
            author
          }
        }
      }
    `
  )

  let metaData = site.siteMetadata;

  if(location) {
    let path = location.pathname + location.hash;
    path = path[path.length - 1] === "/"? path.slice(0, -1) : path;
    metaData = MetaData[path] ? MetaData[path] : site.siteMetadata;
  }

  return (
    <Helmet
      title={metaData.title}
    >
      <meta name="description" content={metaData.description}/>
      <meta name="keywords" content={metaData.keywords}/>
    </Helmet>
  )
}

export default SEO
