import blog1 from "../src/images/Blogs/BlogImage1.jpg"
import blog2 from "../src/images/Blogs/BlogImage2.jpg"
import blog3 from "../src/images/Blogs/BlogImage3.jpg"

export const mediaData = [
  {
    link: "https://www.youtube.com/watch?v=zO1GDko4HTE",
    author_name: "The Adecco Group, India",
    img: "https://i.ytimg.com/vi/zO1GDko4HTE/hqdefault.jpg",
    type: "video",
    title: "#LifeAtAdecco | Sanketh Chengappa",
  },
  {
    title: "Adecco Global Corporate video - #Adecco #Pontoon & #LHH",
    img: "https://i.ytimg.com/vi/DCufY4b2XgQ/hqdefault.jpg",
    type: "video",
    link: "https://www.youtube.com/watch?v=Rson7582i2c",
    author_name: "The Adecco Group, India",
  },
  {
    img: "https://i.ytimg.com/vi/Rson7582i2c/hqdefault.jpg",
    type: "video",
    link: "https://www.youtube.com/watch?v=9hHJUvr3avY",
    author_name: "The Adecco Group, India",
    title: "Global Talent Competitiveness Index 2020.",
  },
  {
    type: "video",
    img: "https://i.ytimg.com/vi/Rson7582i2c/hqdefault.jpg",
    title: "Global Talent Competitiveness Index 2020.",
    link: "https://www.youtube.com/watch?v=DCufY4b2XgQ",
    author_name: "The Adecco Group, India",
  },
  {
    title: "Aditya Bansal, CEO for One Month 2019, The Adecco Group India",
    link: "https://www.youtube.com/watch?v=Ii2koaKl7-w&t=4s",
    author_name: "The Adecco Group, India",
    type: "video",
    img: "https://i.ytimg.com/vi/Ii2koaKl7-w/hqdefault.jpg",
  },
]

export const blogVideoData = [
  {
    img: blog1,
    type: "blog",
    title:
      "Governing the disruption digital transformation is imparting to businesses and functions",
    description:
      "Organizations are faced with a quandary on governance as they invest heavily in digital advancements. ",
    link: "/Blog1",
    target: "_self",
  },
  {
    title: "Adecco Global Corporate video - #Adecco #Pontoon & #LHH",
    img: "https://i.ytimg.com/vi/DCufY4b2XgQ/hqdefault.jpg",
    type: "video",
    link: "https://www.youtube.com/watch?v=Rson7582i2c",
    author_name: "The Adecco Group, India",
  },
  {
    img: blog3,
    type: "blog",
    title:
      "Companies that make the most of temp-to-perm hiring can prevent bad hires",
    link: "/Blog3",
    target: "_self",
  },
  {
    img: blog2,
    type: "blog",
    title: "Top thriving IT skills",
    link: "/Blog2",
    target: "_self",
  },
  {
    type: "video",
    img: "https://i.ytimg.com/vi/Rson7582i2c/hqdefault.jpg",
    title: "Global Talent Competitiveness Index 2020.",
    link: "https://www.youtube.com/watch?v=DCufY4b2XgQ",
    author_name: "The Adecco Group, India",
  },
]
