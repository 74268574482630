import React from "react"
import { Icon } from "antd"

const MediaCard = (props: any) => {
  let styles: any = {
    backgroundImage: `url(${props.data.img})`,
    height: props.index ? "200px" : "410px",
    marginRight: props.index ? "10px" : "5px",
    marginBottom: props.index ? "10px" : "5px",
    backgroundSize: props.index ? "325px 200px" : "325px 410px"
  }
  return (
    <div
      onClick={() => props.onItemClick(props.data)}
      className="parent"
      style={styles}
    >
      <div className="h-100 w-100 d-flex justify-content-center align-items-center flex-column p-1" 
      style={{ boxShadow: "inset 10px -120px 100px -35px black"}}>
        <div className="img">
        {props.data.type === "video" && (
          <Icon type="play-circle" theme="filled" style={{ fontSize: 30 }} />
        )}
      </div>
      <div className="img font-xsmall w-100 h-auto text-align-left">
        <span className="font-normal c-secondary">{props.data.author_name}</span>
        <span className="font-normal fw-600 py-2">{props.data.title}</span>
        {props.data.type === "blog" && (
          <>
            {props.index === 0 && (
              <span className="font-xsmall pb-2">{props.data.description}</span>
            )}
            <a className="c-info font-xsmall mb-1">Read More</a>
          </>
        )}
      </div>
    </div>
    </div >
  )
}

export default MediaCard
