import { digitalBusinessSolutions } from "../../services/digitalBusinessSolutions"
import React, { useState } from "react"
import { Row, Col, Button } from "antd"
//@ts-ignore
import flyer from "../pdf/digital-business-flyer.pdf"
//@ts-ignore
import bannerImage from "../images/background-resetting-normal.jpg"
//@ts-ignore
import flyerImage from "../images/flyer.png"
import { PageContentFullWidth } from "../components/PageContent/PageContent"
import { text } from "../constants"
import { WrappedBusinessEnquiryForm } from "../components/Forms/BusinessEnquiryForm"

const DigitalBusinessSolutions = (props: { location: any }) => {
  const interested = props.location.state ? props.location.state.interested : ""
  const [interestedInData, setInterestedInData] = useState(interested)
  const changeInterestedArea = (value: string) => {
    setInterestedInData(value)
  }
  return (
    <PageContentFullWidth class="pb-9" location={props.location}>
      <Row
        justify="center"
        type="flex"
        className="pos-relative mb-4 justify-content-center"
      >
        <div>
          <img
            src={bannerImage}
            alt="image loading"
            className="w-100 h-11"
            style={{ objectFit: "cover" }}
          />
          <span
            style={{
              height: "100%",
              width: "100%",
              top: "0",
              left: "0",
              opacity: "0.4",
              mixBlendMode: "multiply",
            }}
            className="pos-absolute bg-primary"
          ></span>
        </div>
        <div
          style={{ bottom: "12rem" }}
          className="font-h1 fw-600 pos-absolute c-white p-4s text-align-center justify-content-center"
        >
          {text.DIGITAL_BUSINESS_SOLUTIONS}
        </div>
      </Row>
      {/* <Row type="flex" justify="center" className="pos-relative mb-4 ">
        <Col md={24} xs={24} className="justify-content-center">
          <img src={bannerImage} alt="image loading" className="w-100" />
        </Col>
      </Row> */}

      <Row type="flex" justify="space-around">
        <Col lg={10} md={22} xs={22}>
          <Row
            className="fw-500 font-normal p-2"
            dangerouslySetInnerHTML={{
              __html: digitalBusinessSolutions.description,
            }}
          ></Row>

          <Row
            type="flex"
            justify="space-around"
            className="pos-relative mb-4 align-items-center"
          >
            <Col>
              <Button
                type="danger"
                size="default"
                download
                href={flyer}
                className="font-md-xsmall font-sm-h5 fw-600 mb-4"
              >
                {text.DOWNLOAD_FLYER}
              </Button>
            </Col>
            <Col>
              <img src={flyerImage} alt="image loading" />
              <p className="d-flex justify-content-center p-1 c-primary">
                {text.PREVIEW}
              </p>
            </Col>
          </Row>
        </Col>
        <Col lg={10} md={22} xs={22}>
          <h2 className="text-align-center font-small font-sm-h5 fw-600 c-primary">
            {text.CONTACT_US}
          </h2>
          <WrappedBusinessEnquiryForm
            getInterestedArea={changeInterestedArea}
            showInterestedInValue={interestedInData}
            pageSource={text.DIGITAL_BUSINESS}
          />
        </Col>
      </Row>
    </PageContentFullWidth>
  )
}

export default DigitalBusinessSolutions
