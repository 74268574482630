export const MetaData = {
    "/#welcome": {
        keywords: "placement agency, recruitment consultants, HR services, work from home, HR Solution Staffing, HR solution, staffing",
        title: "Adecco India -  leading HR solution company",
        description: "As the leading HR solution company, We deliver a comprehensive suite of recruitment & career services to organizations & individuals across the employment lifecycle."
    },
    "/#hire": {
        keywords: "employement services, employement agencies, hiring agency",
        title: "Adecco India: employment, hiring and recruitment services",
        description: "Our solutions range from hiring and placing one employee to providing total talent solutions, across a wide range of industries and skills profiles."
    },
    "/#find-job": {
        keywords: "Find a job, jobs in India, Job opportunities in India, Job Vacancy, jobs in bangalore, jobs in mumbai, jobs in chennai, jobs in hyderabad, jobs in ahmedabad, jobs in kolkata, work from home jobs, Permanent hiring, permanent recruitment",
        title: "Find a job: Adecco India Careers",
        description: "Find jobs in your preferred industry in permanent and temporary roles, training and career advice. We help in matching people with quality jobs pan India."
    },
    "/#staffing": {
        keywords: "staffing solutions, staffing agencies in India, staffing services, General staffing, professional staffing, permanent recruitment, outsourcing, International engagement, RPO solution, Digital solution",
        title: "Total HR solutions to meet customer needs. Enquire Now!",
        description: "We have established the most comprehensive suite of HR solutions in the industry, providing essential, dynamic and efficient solutions across India."
    },
    "/staffing-expertise/#general_staffing": {
        keywords: "general staffing, staffing firm, general labor staffing agencies near me, general labor staffing agency, temporary staffing, temporary staffing service, temp recruitment, contract staffing, manpower staffing, part time staffing, contractual staffing",
        title: "General Staffing - Adecco India",
        description: "General Staffing: placing associates with organizations on a temporary basis, providing flexibility to employers and new opportunities to candidates. Enquire now!"
    },
    "/staffing-expertise/#professional_staffing": {
        keywords: "professional staffing, professional Staffing services, professional staffing group, professional staffing solutions, professional staffing companies, professional staffing firms, IT staffing, it staffing agencies, it staffing companies, engineering staffing agencies, engineering staffing companies, pharmaceutical staffing agencies, pharma life sciences",
        title: "Professional Staffing - Adecco India",
        description: "Professional Staffing: High-end skill fulfillment across mainstream and niche technologies in IT, engineering, fintech, BFSI, and Lifesciences. Enquire now!"
    },
    "/staffing-expertise/#permanent_recruitment": {
        keywords: "permanent recruitment, recruitment strategies, Permanent recruitment services, Permanent recruitment solutions, Recruitment services, Recruitment services in India",
        title: "Permanent Recruitment - Adecco India",
        description: "Permanent Recruitment: We help employers to recruit talent for permanent roles, securing the skills needed for an organization’s ongoing success. Enquire now!"
    },
    "/staffing-expertise/#international_outsourcing": {
        keywords: "outsourcing,  payroll outsourcing companies, outsourcing company, outsourcing services, Merchandising, office operations, Outsourcing business process, outsourcing hrm",
        title: "Outsourcing - Adecco India",
        description: "We enable clients to focus on core business activities by outsourcing their field force, merchandising, office operations, and support functions to us. Enquire now!"
    },
    "/staffing-expertise/#rpo_lite": {
        keywords: "recruitment process outsourcing, recruitment process outsourcing companies, recruitment process outsourcing india, recruitment process outsourcing meaning",
        title: "RPO Lite/Recruitment Pocess Outsourcing - Adecco India",
        description: "RPO Lite: We can take charge of entire components or parts of your recruitment process outsourcing to provide best-in-class delivery with continuous optimization."
    },
    "/staffing-expertise/#international_engagement": {
        keywords: "international engagement, international engagement strategy, international hiring companies, shared services, Offshore recruitment, offshore delivery center",
        title: "International Engagement - Adecco India",
        description: "We partner with clients for their international engagement and anticipated staffing needs to create an efficient and accountable program. Enquire Now!"
    },
    "/staffing-expertise/#hire_train_deploy": {
        keywords: "hire train deploy, hire train, hire train and deploy",
        title: "Hire Train & Deploy - Adecco India",
        description: "Hire Train Deploy: We hire the right candidate, provide customized training, and deploy with the client in a tailor-made approach to unique workforce needs."
    },
    "/staffing-expertise/#lhh": {
        keywords: "LHH, talent development, career transition, career transition services",
        title: "LHH - Adecco India",
        description: "Talent Development & Career Transition: LHH is the leading global provider of talent and leadership development, career transition, and coaching. Enquire now!"
    },
    "/staffing-expertise/#pontoon": {
        keywords: "RPO Solution, managed service provider, global managed services, recruitment outsourcing",
        title: "MSP & RPO - Adecco India",
        description: "We offer fully integrated HR solutions MSP and RPO to ensure faster fill rates and improved quality through more robust supplier engagement. Enquire now!"
    },
    "/staffing-expertise/#digital_solutions": {
        keywords: "digital solution, digital solutions company, application development, AI Staffing, data and analytics, Internet of things, cloud migration, business intelligence, Machine Learning",
        title: "Digital Business Solution - Adecco India",
        description: "Digital business solutions: best in class digital solutions and innovation-led transformation, AI & Machine learning, IoT, Data Analytics. Enquire now!"
    },
    "/media-coverage": {
        keywords: "media coverage, press release",
        title: "Media Coverage - Adecco India",
        description: "The official site for media coverage in print, online & visual media–news, press releases, PR contact information & press kits. Contact us for media inquiries."
    },
    "/about-us": {
        keywords: "about us, overview",
        title: "About us - Adecco India",
        description: "We support companies at all stages of their development and throughout the business cycle and to support candidates and associates throughout their careers."
    },
    "/award-recognition": {
        keywords: "Awards & Recognitions, awards, recognition award",
        title: "Awards & Recognition - Adecco India",
        description: "Awards & Recognition: Great Place to Work®, also recognized in different categories of awards, from staffing to outsourcing to corporate social responsibility."
    },
    "/our-values": {
        keywords: "our values, entrepreneurship, responsibility, team spirit, customer centricity, passion",
        title: "Our values - Adecco India",
        description: "The values that inspire growth for our people, culture, and all our stakeholders - Passion, Entrepreneurship, Responsibility, Team spirit & Customer-centricity."
    },
    "/blogs-and-vlogs": {
        keywords: "blogs, blogs on HR, blogs on Human Resource management, blogs on digital trnasformation, workplace",
        title: "Blogs & Vlogs - Adecco India",
        description: "Our Blog section provides an inside view of our people, careers, work, and workplace insights. See how we are preparing for the future of work in everything we do."
    },
    "/videos": {
        keywords: "videos on human resource, videos on human resource management, employement, HR",
        title: "Adecco India videos",
        description: "Watch the latest employment, HR, digital solutions videos from Adecco India. We help thousands of people find exciting new careers & businesses find the right talent."
    },
    "/corporate-social-responsibility": {
        keywords: "corporate social Responsibility, corporate social Responsibility examples",
        title: "Corporate social responcibility - Adecco India",
        description: "Corporate Social Responsibility: Our flagship programmes CEO for One Month and Win4Youth drive inclusive, social value creation at the local and global level."
    },
    "/contact-us": {
        keywords: "adecco contact details, contact us, adecco contact information, contact adecco staffing, adecco india pvt ltd contact details",
        title: "Contact us - Adecco India",
        description: "Contact Us. For general enquiries, please contact our head office. Looking for someone a little closer to home? Get in touch with one of our branch locations."
    },
    "/locations": {
        keywords: "adecco locations, locations",
        title: "Locations - Adecco India",
        description: "Adecco India headquartered in Bangalore, with branch offices pan India in metro & regional locations. Contact your local office to get in touch with us today."
    },
    "/submit-your-cv": {
        keywords: "Submit your cv online, Submit your cv, Job Seeker, Job Seekers Website, Job seeker sites, Job seeker network, work from home online jobs",
        title: "Submit your CV - Adecco India",
        description: "Send us your CV or resume, an Adecco team member will get in touch with you. Our expertise, tools, and network connect people with the right job opportunities."
    },
    "/refer-a-friend": {
        keywords: "Refer a friend, job opening, Jobs in India",
        title: "Got a friend looking for a job? Refer a friend to Adecco.",
        description: "Refer a friend or colleague to work at Adecco. It will help us drive our ongoing success as a leading provider of HR solutions in India. Contact us today!"
    },
    "/faqs": {
        keywords: "FAQ, frequently asked questions",
        title: "Frequently asked questions - Adecco India",
        description: "Frequently asked questions: Here are the most commonly asked questions about applying to and working at Adecco. Gather all the information you need. Contact us."
    },
    "/security": {
        keywords: "security, protect yourself",
        title: "Security - Adecco India",
        description: "We have not authorized any agency, company or individual to collect money or request any monetary arrangement in order to receive a job offer from Adecco."
    }
}