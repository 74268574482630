import React from "react"
import { Row } from "antd"
import "../styles/app.less"

import { faqData } from "../../services/FaqData"
import Collapsible from "../components/Collapsible/Collapsible"
import PageContent from "../components/PageContent/PageContent"

export default function faqs(props) {
  return (
    <PageContent location={props.location} class="bg-white">
      <div>
        <h3 className="text-align-center heading-3 pt-5">{faqData.title}</h3>
        <Row
          type="flex"
          justify="center"
          className="text-align-center pb-2 px-lg-0 px-5"
        >
          <Row
            className="font-large lh-md-x-large lh-medium"
            dangerouslySetInnerHTML={{ __html: faqData.description }}
            style={{ lineHeight: "2.5rem", opacity: "0.9" }}
          ></Row>
        </Row>
        <Row className="px-md-0 px-4 font-h1">
          <Collapsible faqData={faqData.faqs} />
        </Row>
      </div>
    </PageContent>
  )
}
