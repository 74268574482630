import React from "react"
import { PageContentFullWidth } from "../components/PageContent/PageContent"
import TestimonialScreen from "../components/HomePage/TestimonialScreen";
import data from "../../services/homepageData.ts"

const ClientTestimonial = (props) => {

    return (
        <PageContentFullWidth location={props.location} class="pt-5">
            <TestimonialScreen pageData={data.testimonial} showGoToTop="0" />
        </PageContentFullWidth>
    )
}

export default ClientTestimonial;

