import React from "react"
//@ts-ignore
import goToTopIcon from "../../images/go-to-top.png"

const GoToTopBtn = () => {
  const handleGoToTop = () => {
    ;(window as any).fullpage_api.moveTo(1)
  }
  return (
    <div>
      <img
        onClick={handleGoToTop}
        className="go-to-top pos-absolute "
        style={{bottom:"0px"}}
        src={goToTopIcon}
      />
    </div>
  )
}

export default GoToTopBtn
