import React, { Fragment, useState, useEffect } from "react"
import {
  Form,
  Button,
  Checkbox,
  Input,
  Row,
  Cascader,
  message,
  Select,
  Col,
} from "antd"
import TextArea from "antd/lib/input/TextArea"
import ModalComponent from "../ModalComponent/Modal"
import { TermsConditionData } from "../../../services/termsAndConditionData"
import {
  checkError,
  disableSubmitCheck,
  characterValidation,
  phoneNumberValidation,
  validationRegex,
  onlyNumValue,
  emailValidation,
  countryCodeData,
  checkMaxDigitLimit,
} from "./FormUtils"
import Axios from "axios"

const { Option } = Select
const formFields = [
  "employeeName",
  "countryCode",
  "phoneNumber",
  "location",
  "referralEmail",
  "query",
]

export function ContactUsForm({ form }) {
  const placeholder = "*"
  const { getFieldDecorator } = form
  const [modalVisibility, setModalVisibility] = useState()
  const [agreement, setAgreement] = useState(true)
  const [disableSubmit, setDisableSubmit] = useState(true)
  const [emptyForm, setEmptyForm] = useState(true)
  const [country, setCountry] = useState("India:+91")

  const handleSubmit = (e: any) => {
    e.preventDefault()
    form.validateFieldsAndScroll((err: any, values: any) => {
      if (!err) {
        const countryData = values.countryCode.split(":")
        Axios.post(`${process.env.GATSBY_MIDDLEWARE_API}api/EmailTrigger`, JSON.stringify({
          Name: values.employeeName,
          "Email Address": values.referralEmail,
          "Phone Number": countryData[1] + values.phoneNumber,
          country: countryData[0],
          Location: values.location,
          Query: values.query,
          type: "contact_us",
        }))
        // fetch(`${process.env.GATSBY_MIDDLEWARE_API}api/EmailTrigger`, {
        //   method: "POST",
        //   body: JSON.stringify({
        //     Name: values.employeeName,
        //     "Email Address": values.referralEmail,
        //     "Phone Number": countryData[1] + values.phoneNumber,
        //     country: countryData[0],
        //     Location: values.location,
        //     Query: values.query,
        //     type: "contact_us",
        //   }),
        // })
        message.success("The form has been successfully submitted.")
        setEmptyForm(true)
        form.resetFields()
      } else {
        message.error("We encountered an error while submitting the form.")
      }
    })
  }

  const handleModalView = () => {
    setModalVisibility(true)
  }

  const changeModalView = (value: boolean) => {
    setModalVisibility(value)
  }

  const checkCheckBox = (rule, value, callback) => {
    if (!value) {
      setAgreement(false)
      callback("Please agree the terms and conditions!")
    } else {
      setAgreement(true)
      callback()
    }
  }

  const onlyNum = () => {}

  useEffect(() => {
    var errorData = checkError({ formFields, form })
    var { errorInData, fieldCount } = errorData

    if (fieldCount === formFields.length) {
      setEmptyForm(false)
    }
    setDisableSubmit(disableSubmitCheck(errorInData, emptyForm, agreement))
  })

  return (
    <Fragment>
      <Form onSubmit={handleSubmit}>
        <Form.Item className="mb-2">
          {getFieldDecorator("employeeName", {
            initialValue: "",
            validateTrigger: "onBlur",
            rules: [
              {
                required: true,
                message: "Please enter your name",
              },
              { max: 50, message: "Character limit exceeded" },
              {
                pattern: characterValidation,
                message: "Please enter valid characters",
              },
            ],
          })(<Input size="large" placeholder={"Name ".concat(placeholder)} />)}
        </Form.Item>
        <Form.Item className="mb-2">
          {getFieldDecorator("referralEmail", {
            initialValue: "",
            validateTrigger: "onBlur",
            rules: [
              {
                pattern: emailValidation,
                message: "Please enter a valid email!",
              },
              {
                required: true,
                message: "Please enter your E-mail!",
              },
            ],
          })(<Input size="large" placeholder="Email *" />)}
        </Form.Item>
        <Row type="flex">
          <Col span={24} lg={10}>
            <Form.Item className="mb-2 mr-0 mr-md-2">
              {getFieldDecorator("countryCode", {
                validateTrigger: "onBlur",
                initialValue: "India:+91",
                rules: [
                  {
                    required: true,
                    message: "Please select your country code",
                  },
                ],
              })(
                <Select
                  size="large"
                  onChange={(value: string) => setCountry(value)}
                >
                  {countryCodeData.map((data, index) => {
                    return (
                      <Option
                        key={index}
                        value={data.country + ":" + data.countryCode}
                      >
                        {data.country + " : " + data.countryCode}
                      </Option>
                    )
                  })}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={24} lg={14}>
            <Form.Item className="mb-2 mr-0 mr-md-2">
              {getFieldDecorator("phoneNumber", {
                validateTrigger: "onBlur",
                rules: [
                  {
                    required: true,
                    message: "Please enter your phone number",
                  },
                  {
                    pattern: phoneNumberValidation,
                    message: "Please enter valid digits!",
                  },
                  {
                    validator: (rule, value, callback) =>
                      checkMaxDigitLimit(rule, value, callback, country),
                  },
                ],
              })(
                <Input
                  size="large"
                  placeholder="Phone Number *"
                  onKeyPress={e => {
                    onlyNumValue(e)
                  }}
                />
              )}
            </Form.Item>
          </Col>
        </Row>

        <Form.Item className="mb-2">
          {getFieldDecorator("location", {
            validateTrigger: "onBlur",
            rules: [
              {
                required: true,
                message: "Please enter your location!",
              },
              {
                pattern: validationRegex,
                message: "Please enter valid string",
              },
              { max: 250, message: "Character limit exceeded" },
              // {
              //   validator: (rule, value, callback) =>
              //     checkRequiredValue(rule, value, callback, "location"),
              // },
            ],
          })(<Input size="large" placeholder="Current Location*" />)}
        </Form.Item>
        <Form.Item className="mb-2">
          {getFieldDecorator("query", {
            validateTrigger: "onBlur",
            rules: [
              {
                required: true,
                message: "Please enter your query",
              },
              {
                pattern: validationRegex,
                message: "Please enter valid string",
              },
              { min: 10, message: "Minimum 10 characters are required" },
              { max: 250, message: "Character limit exceeded" },
            ],
          })(
            <TextArea
              autosize={false}
              rows={4}
              placeholder="Query *"
              style={{ resize: "none" }}
            />
          )}
        </Form.Item>
        <Form.Item className="mb-3">
          {getFieldDecorator("agreement", {
            valuePropName: "checked",
            initialValue: "true",
            rules: [{ validator: checkCheckBox }],
          })(
            <div>
              {" "}
              <Checkbox className="c-secondary pr-2" defaultChecked={true}>
                I agree to the{" "}
              </Checkbox>
              <a>
                <small className="c-info" onClick={handleModalView}>
                  Terms and conditions
                </small>
              </a>
              {modalVisibility && (
                <ModalComponent
                  title="Terms and condition"
                  isVisible={modalVisibility as boolean}
                  getValueModal={changeModalView}
                  modalData={TermsConditionData()}
                />
              )}
            </div>
          )}
        </Form.Item>
        <Form.Item>
          <Button
            size="large"
            type="danger"
            htmlType="submit"
            className="w-50"
            disabled={disableSubmit}
          >
            Submit
          </Button>
          <Row type="flex" className="mb-5 lead">
            <small>
              Or write in to us at:&nbsp;
              <a href="mailto:in.marcom@adecco.com">in.marcom@adecco.com</a>
            </small>
          </Row>
        </Form.Item>
      </Form>
    </Fragment>
  )
}

export const WrappedContactUsForm = Form.create({ name: "contact-us" })(
  ContactUsForm
)
