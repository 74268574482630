import React, { useState, useEffect } from "react"
import { Form, Button, Checkbox, Input, Divider, message, Select } from "antd"
import "./formStyle.less"
import TextArea from "antd/lib/input/TextArea"
import FormItem from "antd/lib/form/FormItem"
import ModalComponent from "../ModalComponent/Modal"
import { TermsConditionData } from "../../../services/termsAndConditionData"
import {
  checkError,
  disableSubmitCheck,
  characterValidation,
  validationRegex,
  jobTitleValidation,
  emailValidation,
  validateFileType,
  validateFileSize,
} from "./FormUtils"
import uuid from "react-uuid"
import Axios from "axios"

const { Option } = Select

const formFields = [
  "employeeFirstName",
  "employeeLastName",
  "employeeEmail",
  "currentEmployee",
  "referralFirstName",
  "referralLastName",
  "referralEmail",
  "jobTitle",
  "location",
  "resume",
  "comments",
]

export function ReferAFriendForm({ form }) {
  const [modalVisibility, setModalVisibility] = useState()
  const [filename, setFilename] = useState("")
  const [isFileUploading, setIsFileUploading] = useState(false)
  const [disableSubmit, setDisableSubmit] = useState(true)
  const [emptyForm, setEmptyForm] = useState(true)
  const [agreement, setAgreement] = useState(true)

  const handleSubmit = (e: any) => {
    e.preventDefault()
    form.validateFieldsAndScroll((err: any, values: any) => {
      if (!err) {
        Axios.post(`${process.env.GATSBY_MIDDLEWARE_API}api/EmailTrigger`, JSON.stringify({
          employeeFirstName: values.employeeFirstName,
          employeeLastName: values.employeeLastName,
          employeeEmail: values.employeeEmail,
          currentEmployee: values.currentEmployee,
          referralFirstName: values.referralFirstName,
          referralLastName: values.referralLastName,
          referralEmail: values.referralEmail,
          jobTitle: values.jobTitle,
          location: values.location,
          comments: values.comments,
          agreement: values.agreement.toString(),
          attachment: filename,
          type: "refer_a_friend",
        }))
        // fetch(`${process.env.GATSBY_MIDDLEWARE_API}api/EmailTrigger`, {
        //   method: "POST",
        //   body: JSON.stringify({
        //     employeeFirstName: values.employeeFirstName,
        //     employeeLastName: values.employeeLastName,
        //     employeeEmail: values.employeeEmail,
        //     currentEmployee: values.currentEmployee,
        //     referralFirstName: values.referralFirstName,
        //     referralLastName: values.referralLastName,
        //     referralEmail: values.referralEmail,
        //     jobTitle: values.jobTitle,
        //     location: values.location,
        //     comments: values.comments,
        //     agreement: values.agreement.toString(),
        //     attachment: filename,
        //     type: "refer_a_friend",
        //   }),
        // })
        message.success("The form has been successfully submitted.")
        setEmptyForm(true)
        form.resetFields()
      } else {
        message.error("We encountered an error while submitting the form.")
      }
    })
  }

  const { getFieldDecorator } = form

  const handleModalView = () => {
    setModalVisibility(true)
  }

  const changeModalView = (value: boolean) => {
    setModalVisibility(value)
  }

  const checkAgreement = (rule, value, callback) => {
    if (!value) {
      setAgreement(false)
      callback("Please agree the terms and conditions!")
    } else {
      setAgreement(true)
      callback()
    }
  }

  const getBase64 = file => {
    let reader = new FileReader()
    return new Promise((resolve, reject) => {
      reader.readAsDataURL(file)
      reader.onload = function() {
        let result: any = reader.result
        result = result.split("base64")[1]
        resolve(result)
      }
    })
  }

  const onFileChange = async (data: any) => {
    setIsFileUploading(true)
    let files = data.target.files
    let filenameSplit = files[0].name.split(".")
    if (!validateFileType(filenameSplit[filenameSplit.length - 1])) {
      data.target.value = null
      setIsFileUploading(false)
      return
    }
    if (!validateFileSize(files[0])) {
      data.target.value = null
      setIsFileUploading(false)
      return
    }
    setIsFileUploading(true)
    let filename = `${uuid()}_resume.${filenameSplit[filenameSplit.length - 1]}`
    const base64Format = await getBase64(files[0])
    Axios.post(`${process.env.GATSBY_MIDDLEWARE_API}api/uploadFile`, JSON.stringify({
      file_base_64: base64Format,
      filename: filename,
    }))
    .then(data => {
      setIsFileUploading(false)
      setFilename(filename)
    })
    .catch(err => setIsFileUploading(false))
    // fetch(`${process.env.GATSBY_MIDDLEWARE_API}api/uploadFile`, {
    //   method: "POST",
    //   body: JSON.stringify({
    //     file_base_64: base64Format,
    //     filename: filename,
    //   }),
    // })
    //   .then(data => {
    //     setIsFileUploading(false)
    //     setFilename(filename)
    //   })
    //   .catch(err => setIsFileUploading(false))
  }

  useEffect(() => {
    var errorData = checkError({ formFields, form })
    var { errorInData, fieldCount } = errorData

    if (fieldCount >= formFields.length) {
      setEmptyForm(false)
    }

    setDisableSubmit(disableSubmitCheck(errorInData, emptyForm, agreement))
  })

  return (
    <Form onSubmit={handleSubmit}>
      <div className="form-content overflow-auto pt-2 h-auto h-md-10">
        <p className="lead">Your information</p>
        <Form.Item className="mb-2  mr-0 mr-md-2">
          {getFieldDecorator("employeeFirstName", {
            validateTrigger: "onBlur",
            rules: [
              {
                required: true,
                message: "Please enter your firstname",
              },
              { max: 50, message: "Character limit exceeded" },
              {
                pattern: characterValidation,
                message: "Please enter valid characters",
              },
            ],
          })(<Input size="large" placeholder="First Name *" />)}
        </Form.Item>
        <Form.Item className="mb-2 mr-0 mr-md-2">
          {getFieldDecorator("employeeLastName", {
            validateTrigger: "onBlur",
            rules: [
              {
                required: true,
                message: "Please enter your lastname!",
              },
              { max: 50, message: "Character limit exceeded" },
              {
                pattern: characterValidation,
                message: "Please enter valid characters",
              },
            ],
          })(<Input size="large" placeholder="Last Name *" />)}
        </Form.Item>
        <Form.Item className="mb-2 mr-0 mr-md-2">
          {getFieldDecorator("employeeEmail", {
            validateTrigger: "onBlur",
            rules: [
              {
                pattern: emailValidation,
                message: "Please enter a valid email!",
              },
              {
                required: true,
                message: "Please input your E-mail!",
              },
            ],
          })(<Input size="large" placeholder="Email *" />)}
        </Form.Item>
        <Form.Item className="mr-0 mr-md-2 custom-dropdown">
          {getFieldDecorator("currentEmployee", {
            validateTrigger: "onBlur",
            rules: [
              {
                required: true,
                message: "Please enter your details!",
              },
              { max: 50, message: "Character limit exceeded" },
              {
                pattern: characterValidation,
                message: "Please enter valid characters",
              },
            ],
          })(
            <Select
              size="large"
              placeholder="Current Employee at adecco? *"
              getPopupContainer={t => t.parentNode as HTMLElement}
            >
              <Option value="Yes">Yes</Option>
              <Option value="No">No</Option>
            </Select>
          )}
        </Form.Item>
        <Divider className="mb-2 mr-0 mr-md-2" />
        <p className="lead">Your referral Information</p>
        <Form.Item className="mb-2 mr-0 mr-md-2">
          {getFieldDecorator("referralFirstName", {
            validateTrigger: "onBlur",
            rules: [
              {
                required: true,
                message: "Please enter your firstname!",
              },
              { max: 50, message: "Character limit exceeded" },
              {
                pattern: characterValidation,
                message: "Please enter valid characters",
              },
            ],
          })(<Input size="large" placeholder="First Name *" />)}
        </Form.Item>
        <Form.Item className="mb-2 mr-0 mr-md-2">
          {getFieldDecorator("referralLastName", {
            validateTrigger: "onBlur",
            rules: [
              {
                required: true,
                message: "Please enter your lastname!",
              },
              { max: 50, message: "Character limit exceeded" },
              {
                pattern: characterValidation,
                message: "Please enter valid characters",
              },
            ],
          })(<Input size="large" placeholder="Last Name *" />)}
        </Form.Item>
        <Form.Item className="mb-2 mr-0 mr-md-2">
          {getFieldDecorator("referralEmail", {
            validateTrigger: "onBlur",
            rules: [
              {
                pattern: emailValidation,
                message: "Please enter a valid email!",
              },
              {
                required: true,
                message: "Please input your E-mail!",
              },
            ],
          })(<Input size="large" placeholder="Email *" />)}
        </Form.Item>
        <Form.Item className="mb-2 mr-0 mr-md-2">
          {getFieldDecorator("jobTitle", {
            validateTrigger: "onBlur",
            rules: [
              {
                required: true,
                message: "Please input your job title!",
              },
              { max: 50, message: "Character limit exceeded" },
              {
                pattern: jobTitleValidation,
                message: "Please enter valid characters",
              },
            ],
          })(<Input size="large" placeholder="Job Title *" />)}
        </Form.Item>
        <Form.Item className="mb-2 mr-0 mr-md-2">
          {getFieldDecorator("location", {
            validateTrigger: "onBlur",
            rules: [
              {
                required: true,
                message: "Please input your Location!",
              },
              { max: 250, message: "Character limit exceeded" },
            ],
          })(<Input size="large" placeholder="Location *" />)}
        </Form.Item>
        <div className="mb-3 lead c-secondary">
          Submit your cover letter or resume *
        </div>
        <Form.Item className="mr-0 mr-md-2">
          {getFieldDecorator("resume", {
            rules: [
              {
                required: true,
                message: "Please upload resume!",
              },
            ],
          })(
            // <Upload name="logo" action="/upload.do" listType="picture">
            //   <Button
            //     size="large"
            //     className="c-info"
            //     style={{ border: "1px solid #00b6ef" }}
            //   >
            //     <Icon type="upload" /> Add File
            //   </Button>
            // </Upload>
            <Input
              type="file"
              onChange={onFileChange}
              accept=".pdf,.doc,.docx"
              size="large"
            />
          )}
        </Form.Item>

        <Form.Item className="mb-2 mr-0 mr-md-2">
          {getFieldDecorator("comments", {
            validateTrigger: "onBlur",
            rules: [
              {
                required: true,
                message: "Please input your comments",
              },
              {
                pattern: validationRegex,
                message: "Please enter valid string",
              },
              { min: 10, message: "Minimum 10 characters are required" },
              { max: 250, message: "Character limit exceeded" },
            ],
          })(
            <TextArea
              autosize={false}
              rows={4}
              placeholder="Comments *"
              style={{ resize: "none" }}
            />
          )}
        </Form.Item>
      </div>
      <FormItem className="mb-3">
        {getFieldDecorator("agreement", {
          valuePropName: "checked",
          initialValue: "true",
          rules: [{ validator: checkAgreement }],
        })(
          <div>
            <Checkbox className="c-secondary pr-2" defaultChecked={true}>
              I agree to the{" "}
            </Checkbox>
            <a>
              <small className="c-info" onClick={handleModalView}>
                Terms and conditions
              </small>
            </a>
            {modalVisibility && (
              <ModalComponent
                title="Terms and condition"
                isVisible={modalVisibility as boolean}
                getValueModal={changeModalView}
                modalData={TermsConditionData()}
              />
            )}
          </div>
        )}
      </FormItem>
      <Form.Item>
        <Button
          size="large"
          type="danger"
          htmlType="submit"
          className="mb-3 w-50"
          disabled={disableSubmit}
          loading={isFileUploading}
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  )
}

export const WrappedReferAFriendForm = Form.create({ name: "refer-a-friend" })(
  ReferAFriendForm
)
