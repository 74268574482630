import HelpCenterImg from "../src/images/PF_help_centre_image.png"
import pfPresentation from "../src/pdf/PF Self Help Online Portal_Final.pdf"
import EscalationMatrix from "../src/images/EscalationMatrix.png"
import presentationPreviewImg from "../src/images/PF_Self_Help_Preview.png";

export const HelpCenterData = {
    PFHelpCenter: {
        img: HelpCenterImg,
        author: "",
        title: "Welcome To The PF Help Centre",
        description: `As an Adecco associate, you can now use the PF Self Help Portal for a wide range of features including transfer- out & withdrawal process. It is secure and based on authentication information provided by Adecco for service access and can be viewed only by the associate.`,
        contents: [
            {
                subTitle: "Keep These Documents Handy While Applying",
                contentList: [
                    "Copy of any payslip which contains employee the associate's PF number (Preferably the latest payslip copy).",
                    "Copy of the Relieving Letter.",
                    "One set of self-signed cancelled cheque leaf of your bank account which has been updated in the UAN in the EPFO database (with your name printed on the cheque leaf, if your name has not been printed in the cancelled Cheque leaf, please enclose your bank statement as well).",
                    "One set of self-attested PAN card and Aadhar card copy.",
                    "Employee's Contact Details: Mobile Number and Email ID.",
                ],
                footerContent: "Once the forms are received by the PF team, Form 19 will be validated and processed by the PF Trust and the amount will be credited to the associate's account within 45 working days.",
            },
            {
                subTitle: "Project Escalation Matrix",
                image: EscalationMatrix
            }
        ],
        attachment: pfPresentation,
        attachmentPreviewImage: presentationPreviewImg
    }
}