export const CustomCarouselData = [
    {
        title: "Welcome to Adecco. If your query is related to associate PF transfer-out or withdrawal visit - ",
        linkTitle: "PF Help Centre",
        link: "/PF-help-centre"
    },
    {
        title: `For all other associate queries, write to us at `,
        linkTitle: "ASC@adecco.co.in",
        link: "ASC@adecco.co.in",
        isEmail: true
    }
]