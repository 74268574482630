import React from "react"
import data from "../../services/aboutUsData"
import OurTeam from "../components/AboutUsFooter/ourTeam"
import  { PageContentFullWidth, PageContentInnerSection } from "../components/PageContent/PageContent";
import AboutUsDetails from "../components/AboutUsFooter/aboutUsDetails"
import { IDefaultPageProps } from "../constants";

class AboutUs extends React.Component<IDefaultPageProps> {
    render() {
        return (
                <PageContentFullWidth class="pb-9" location={this.props.location}>
                <PageContentInnerSection class="bg-white min-vh-100 py-5 px-4 px-md-0">
                    <OurTeam teamData={data} />
                </PageContentInnerSection>
                <PageContentInnerSection class="bg-secondary min-vh-content py-3">
                    <AboutUsDetails />
                </PageContentInnerSection>
            </PageContentFullWidth>
        )
    }
}

export default AboutUs