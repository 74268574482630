import React from "react"
import ReactFullpage, { Slide } from "@fullpage/react-fullpage"
import data from "../../services/homepageData"
import "../styles/app.less"
import AboutUsScreen from "../components/HomePage/AboutUsScreen"
import HomeScreen from "../components/HomePage/HomeScreen"
import DreamJobScreen from "../components/HomePage/DreamJobScreen"
import HireTalentScreen from "../components/HomePage/HireTalentScreen"
import SolutionsScreen from "../components/HomePage/SolutionsScreen"
import upArrowIcon from "../images/arrow-up.png"
import downArrowIcon from "../images/arrow-down.png"
import Footer from "../components/Footer/Footer"
import VideoModal from "../components/VideoModal/VideoModal"
import {
  PageContentFullWidth,
  PageContentHomeScreen,
} from "../components/PageContent/PageContent"
import "../components/HomePage/HomePageStyle.less"
import { DisclaimerData } from "../../services/disclaimerData.ts"
import Disclaimer from "../components/Disclaimer/disclaimer"
import ASafeReturnToPhysicalWorkPlace from "../components/HomePage/ASafeReturnToPhysicalWorkPlace"
import { IDefaultPageProps } from "../constants"
import CustomCarousel from "../components/CustomCarousel/CustomCarousel"
const SEL = "custom-section"
const SECTION_SEL = `.${SEL}`

class App extends React.Component<IDefaultPageProps> {
  intervalId
  constructor(props) {
    super(props)
    this.intervalId = null
    this.state = {
      showVideoModal: false,
      selectedMediaItem: {},
      fullpageApiObject: null,
      disclaimerVisibility: false,
      buttonVisibility: false,
    }
  }

  componentDidMount() {
    let visited = sessionStorage["alreadyVisited"]

    if (visited && sessionStorage.getItem("clicked")) {
      this.setState({ disclaimerVisibility: false })
    } else {
      sessionStorage["alreadyVisited"] = true
      this.setState({ disclaimerVisibility: true })
    }

    let self = this
    let navDots = document.querySelector("#fp-nav")
    let downBtn = document.createElement("img")
    let upBtn = document.createElement("img")
    let body = document.getElementById("___gatsby")
    upBtn.src = upArrowIcon
    upBtn.setAttribute("class", "nav-arrow")
    downBtn.setAttribute("class", "nav-arrow")
    downBtn.src = downArrowIcon
    downBtn.addEventListener("click", function() {
      window.fullpage_api.moveSectionDown()
    })
    upBtn.addEventListener("click", function() {
      window.fullpage_api.moveSectionUp()
    })
    downBtn.innerHTML = "Down"
    upBtn.innerHTML = "Up"
    navDots.appendChild(downBtn)
    navDots.insertAdjacentElement("afterbegin", upBtn)
    window.onresize = function() {
      window.clearInterval(self.intervalId)
      if (window.innerWidth > 600)
        self.intervalId = setInterval(self.autoScrollPage, 10000)
    }
    if (window.innerWidth > 600)
      self.intervalId = setInterval(self.autoScrollPage, 10000)
  }

  componentWillUnmount() {
    window.clearInterval(this.intervalId)
  }

  onItemClick = (item: any) => {
    if (item.type === "video") {
      this.setState({ selectedMediaItem: item, showVideoModal: true })
    } else {
      window.open(item.link, item.target)
    }
  }

  backgroundsImage = new Array(
    "url(http://placekitten.com/100)",
    "url(http://placekitten.com/200)"
  )

  autoScrollPage = () => {
    const { fullpageApiObject } = this.state
    if (!fullpageApiObject) {
      return
    }
    const currentSection = fullpageApiObject.getActiveSection().index
    if (currentSection !== 3) {
      if (currentSection === 0) {
        fullpageApiObject.moveTo(2)
      }
      if (currentSection === 1) {
        fullpageApiObject.moveTo(3)
      }
      if (currentSection === 2) {
        fullpageApiObject.moveTo(1)
      }
    }
  }

  setFullPageObject = fullpageApiObject => {
    this.setState({ fullpageApiObject })
  }

  changeDisclaimerView = (value: boolean) => {
    this.setState({ setDisclaimerVisibility: value })
  }

  handleDisclaimerButton = (value: boolean) => {
    sessionStorage.setItem("clicked", value)
    this.setState({ buttonVisibility: value })
  }

  render() {
    const {
      selectedMediaItem,
      showVideoModal,
      fullpageApiObject,
      disclaimerVisibility,
    } = this.state

    return (
      <div className="overflowX-hidden">
        {showVideoModal && (
          <VideoModal
            onCloseModal={() => this.setState({ showVideoModal: false })}
            data={selectedMediaItem}
          />
        )}
        <PageContentHomeScreen location={this.props.location}>
          {disclaimerVisibility && (
            <Disclaimer
              title="ATTENTION: RECRUITMENT FRAUD ALERT"
              isVisible={disclaimerVisibility as boolean}
              getValueDisclaimer={this.changeDisclaimerView}
              disclaimerData={DisclaimerData()}
              getValueButton={this.handleDisclaimerButton}
            />
          )}
          <CustomCarousel />
          <ReactFullpage
            anchors={["welcome", "hire", "find-job", "staffing"]}
            navigation={true}
            arrowNavigation={true}
            lazyLoading={true}
            responsiveWidth={480}
            autoScrolling={true}
            fitToSectionDelay={1000}
            fitToSection={false}
            sectionSelector={SECTION_SEL}
            scrollOverflow={true}
            controlArrows={false}
            render={({ state, fullpageApi }) => {
              if (!fullpageApiObject) {
                this.setFullPageObject(fullpageApi)
              }
              return (
                <>
                  <div
                    className={`home-screen custom-section fp-auto-height min-vh-100 fp-noscroll`}
                  >
                    <HomeScreen pageData={data.home_screen} />
                  </div>
                  <div className="hire-talent-screen custom-section fp-auto-height min-vh-100 fp-noscroll">
                    <HireTalentScreen pageData={data.hire_talent} />
                  </div>
                  <div className="dream-job-screen custom-section fp-auto-height min-vh-100 fp-noscroll">
                    <DreamJobScreen pageData={data.dream_job} />
                  </div>
                  {/* <div
                  className="custom-section fp-auto-height  min-vh-100"
                  style={{ backgroundColor: "rgb(147, 147, 147, 0.1)" }}
                  >
                  <TestimonialScreen
                  pageData={data.testimonial}
                  showGoToTop="1"
                  />
                </div> */}
                  {/* <div className="custom-section fp-auto-height min-vh-100">                
                    <BlogsAndVideosScreen onItemClick={this.onItemClick} />
                  </div> */}
                  <div className="custom-section fp-auto-height-responsive">
                    <SolutionsScreen pageData={data.solutions} />
                    <ASafeReturnToPhysicalWorkPlace />
                    <AboutUsScreen pageData={data.about_us} />
                    <Footer pageData={data.footer} />
                  </div>
                </>
              )
            }}
          />
        </PageContentHomeScreen>
      </div>
    )
  }
}

export default App
