import CustomerCentricity from "../src/images/OurValues/customer-centricity.svg"
import Entrepreneurship from "../src/images/OurValues/entrepreneurship.svg"
import Passion from "../src/images/OurValues/passion.svg"
import Responsibility from "../src/images/OurValues/responsibility.svg"
import teamSpirit from "../src/images/OurValues/team-spirit.svg"

const ourValueData = [
    {
        img: Passion,
        title:"Passion",
        content:"We change the world of work, one job at a time."
    },
    {
        img:Entrepreneurship,
        title:"Entrepreneurship",
        content:"We are the industry leader , our goal is to provide more work opportunities for more people."
    },
    {
        img:Responsibility,
        title:"Responsibility",
        content:"We constantly take initiative to make “better work, better life” a daily reality."
    },
    {
        img: teamSpirit,
        title:"Team Spirit",
        content:"We work together as one team."
    },
    {
        img:CustomerCentricity,
        title:"Customer Centricity",
        content:"We live by and for our customer’s success, we want to be their top-of-mind and top-of-heart choice."
    }
]

export default ourValueData