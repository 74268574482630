import React from "react"
import "./HomePageStyle.less"
import { Col, Row } from "antd"
//@ts-ignore
import AboutUsImage from "../../images/About_us.png"
import GoToTopBtn from "./GoToTopBtn"

const AboutUsScreen = ({ pageData }) => {
  return (
    <React.Fragment>
      <div className="py-6 pos-relative">
        {pageData &&
          pageData.map((item: any) => {
            if (item.component_type === "page_header_text") {
              return (
                <Row type="flex" justify="center">
                  <span className="fw-600 font-h5">{item.text.content}</span>
                </Row>
              )
            }
            if (item.component_type === "description_text") {
              return (
                <div>
                  <Row
                    type="flex"
                    className="mt-3 px-6 px-md-9 px-lg-0"
                    justify="center"
                    align="middle"
                  >
                    <Col lg={9} md={24} style={{ textAlign: "justify" }}>
                      <span
                        className="lead-content"
                        dangerouslySetInnerHTML={{ __html: item.text_content }}
                      ></span>
                    </Col>
                    <Col lg={10} className="d-none d-lg-block pl-9">
                      <img
                        src={AboutUsImage}
                        alt="map image"
                        className="mw-100"
                      />
                    </Col>
                  </Row>
                </div>
              )
            }
          })}
        <GoToTopBtn />
      </div>
    </React.Fragment>
  )
}

export default AboutUsScreen
