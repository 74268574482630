import React, { Fragment } from "react"
import "../styles/app.less"

import Header from "../components/Header/Header"
import { privacyPolicyData } from "../../services/PrivacyPolicyData"
import Collapsible from "../components/Collapsible/Collapsible"
import PageContent from "../components/PageContent/PageContent"
import { Row } from "antd"

export default function privacyPolicy(props) {
  return (
    <PageContent class="bg-white" location={props.location}>
      <h3 className="heading-3 pt-5 text-align-center">
        {privacyPolicyData.title}
      </h3>
      <Row type="flex" justify="space-around" className="text-align-center font-large lh-lg-x-large lh-medium px-lg-0 px-6" style={{opacity: "0.9"}}>
        {privacyPolicyData.description.map((data, index) =>
          data.split("\n").map((item, key) => {
            return (
              <Fragment key={key}>
                {item}
                <br />
              </Fragment>
            )
          })
        )}
      </Row>
      <Collapsible faqData={privacyPolicyData.privacyFaqs} />
    </PageContent>
  )
}
