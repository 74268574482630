import React from "react";
import PageContent from "../PageContent/PageContent";
import { Row } from "antd";

const BlogDetails = (props: {pageData}) => {
    const pageData = props.pageData;
    return(
    <PageContent class="px-4 px-md-6 text-align-justify">
        <Row className="pt-6">
        <img src={pageData[0].img} alt="image loading" className="w-100 h-11" style={{objectFit: "cover"}}/></Row>
        <Row className="pt-6 pb-2 c-secondary lead">{pageData[0].author}</Row>
        <h1><b>{pageData[0].title}</b></h1>
        <Row className="fw-500 font-normal" style={{opacity:".8",lineHeight:"1.38"}} dangerouslySetInnerHTML={{__html: pageData[0].description}}></Row>
    </PageContent>
    );
}

export default BlogDetails