import React from "react"
import { Row, Col } from "antd"
import ContactUsImage from "../images/contactUsForm.png"
import { WrappedContactUsForm } from "../components/Forms/ContactUsForm.tsx"
import PageContent from "../components/PageContent/PageContent"

const ContactUs = (props) => {
  return (
    <PageContent location={props.location}>
      <Row className="align-items-center px-6 px-md-0 pt-5">
        <h1 className="heading-3 text-align-center">Contact Us</h1>
        <div className="text-align-center lead">
          We’ve got the largest network of pre-qualified candidates.
        </div>
      </Row>
      <Row type="flex" justify="space-around" className="mt-6 px-6 px-md-0">
        <Col lg={8} md={24} className="d-lg-block d-none">
          <Row>
            <img src={ContactUsImage} className="mw-100" />
          </Row>
          {/* <Row type="flex" justify="center" className="mt-4">
                        <h1><b>Download Our App</b></h1>
                    </Row>
                    <Row type="flex" justify="center">
                        <img src={GooglePlayImage}
                            className="mw-100"
                        />
                    </Row> */}
        </Col>
        <Col lg={10} md={22}>
          <WrappedContactUsForm />
        </Col>
      </Row>
    </PageContent>
  )
}

export default ContactUs
