import React, { useState } from "react"
import BranchData from "../../services/BranchData"
import { Row, Col, Button, Input, Card, Icon } from "antd"
import Map from "../images/mapForBranch.svg"
import "./style.less"
import { location } from "../../services/Constants"
import locationImage from "../images/location.svg"
import PageContent, {
  PageContentFullWidth,
} from "../components/PageContent/PageContent"

const locations = (props) => {
  const [locationList, SetLocationList] = useState(BranchData.BranchDetails)
  const [titleText, setTitleText] = useState("All Branches")
  const [updatedLocationList, setUpdatedLocationList] = useState(
    BranchData.BranchDetails
  )
  const [activeId, setActiveId] = useState("")
  const showAllStates = locationsList => {
    return (
      <Row type="flex" className="px-lg-8 px-2 ml-md-4 mr-md-3">
        {locationsList.map((data, index) => {
          return (
            <Col lg={8} md={12} span={24} key={index} className="p-2 d-flex">
              <Card
                className="m-2 w-100"
                style={{
                  boxShadow: "0 3px 8px rgba(0, 0, 0, 0.16)",
                  borderRadius: "0.35rem",
                }}
              >
                <div className="font-large pb-3">
                  <span>
                    <img src={locationImage} className="pr-2 h-4" />
                  </span>
                  <span>
                    <b>{data.title}</b>
                  </span>
                </div>
                <div className="pb-1 c-secondary font-normal">Adecco India</div>
                <p className="c-secondary font-normal">{data.Address}</p>
                <div>
                  <span>
                    <img src="" />
                  </span>
                  <span className="c-secondary font-normal">
                    {" "}
                    Tel No: {data.TelNo}
                  </span>
                </div>
              </Card>
            </Col>
          )
        })}
      </Row>
    )
  }

  const filterBranchData = (e: any) => {
    let regionId = e.target.getAttribute("id")
    const data = locationList.filter(data => data.key === location[regionId])
    setUpdatedLocationList(data)
    setTitleText(location[regionId])
    setActiveId(regionId)
  }

  const search = (searchData: string) => {
    const searchedData = locationList.filter(data => {
      return data.Address.toLowerCase().includes(searchData.toLowerCase())
    })
    setActiveId("")
    setUpdatedLocationList(searchedData)
    {
      searchData != "" ? setTitleText("") : setTitleText("All Branches")
    }
  }

  return (
    <PageContentFullWidth location={props.location}>
      <div className="bg-white pb-9">
        <div className="heading-3 text-align-center pt-5 pb-3">
          {BranchData.title}
        </div>
        <Row
          type="flex"
          align="middle"
          justify="center"
          className="px-md-8 px-0  w-100"
        >
          <Row type="flex" justify="center" className="w-80">
            <Row className="px-5 px-lg-0 w-100">
              <Input
                placeholder="Find your nearest Adecco branch"
                size="large"
                prefix={<Icon type="search" />}
                onChange={enteredSearchText =>
                  search(enteredSearchText.currentTarget.value)
                }
              ></Input>
            </Row>
            <Row
              type="flex"
              justify="space-between"
              className=" px-5 px-lg-0 my-md-6 my-4 w-100"
            >
              <Col className="pb-1 w-100 w-md-auto">
                <Button
                  onClick={e => filterBranchData(e)}
                  size="large"
                  id="north"
                  className={`c-black w-100 ${
                    activeId === "north" ? "active" : ""
                  }`}
                >
                  {location.north}
                </Button>
              </Col>
              <Col className="pb-1 w-100 w-md-auto">
                <Button
                  onClick={e => filterBranchData(e)}
                  size="large"
                  id="south"
                  className={`c-black w-100 ${
                    activeId === "south" ? "active" : ""
                  }`}
                >
                  {location.south}
                </Button>
              </Col>
              <Col className="pb-1 w-100 w-md-auto">
                <Button
                  onClick={e => filterBranchData(e)}
                  size="large"
                  id="east"
                  className={`c-black w-100 ${
                    activeId === "east" ? "active" : ""
                  }`}
                >
                  {location.east}
                </Button>
              </Col>
              <Col className="pb-1 w-100 w-md-auto">
                <Button
                  onClick={e => filterBranchData(e)}
                  size="large"
                  id="west"
                  className={`c-black w-100 ${
                    activeId === "west" ? "active" : ""
                  }`}
                >
                  {location.west}
                </Button>
              </Col>
            </Row>
          </Row>
        </Row>
        <Row
          type="flex"
          className="justify-content-md-start justify-content-center"
        >
          <h1 className="fw-600 font-h5 px-2 mx-7 px-md-7 pt-2">{titleText}</h1>
        </Row>
        <div>{showAllStates(updatedLocationList)}</div>
      </div>
    </PageContentFullWidth>
  )
}

export default locations
