export const solutions = [
  {
    data: "General Staffing",
    url: "/staffing-expertise/#general_staffing",
    key: "general_staffing",
  },
  {
    data: "Professional Staffing",
    url: "/staffing-expertise/#professional_staffing",
    key: "professional_staffing",
  },
  {
    data: "Permanent Recruitment",
    url: "/staffing-expertise/#permanent_recruitment",
    key: "permanent_recruitment",
  },
  {
    data: "Outsourcing",
    url: "/staffing-expertise/#international_outsourcing",
    key: "international_outsourcing",
  },
  { data: "RPO Lite", url: "/staffing-expertise/#rpo_lite", key: "rpo_lite" },
  {
    data: "International Engagement",
    url: "/staffing-expertise/#international_engagement",
    key: "international_engagement",
  },
  {
    data: "Hire Train Deploy",
    url: "/staffing-expertise/#hire_train_deploy",
    key: "hire_train_deploy",
  },
  
  {
    data: "Talent Development & Career Transition",
    url: "/staffing-expertise/#lhh",
    key: "lhh",
  },
  {
    data: "MSP & RPO",
    url: "/staffing-expertise/#pontoon",
    key: "pontoon",
  },
  {
    data: "Digital Solutions",
    url: "/staffing-expertise/#digital_solutions",
    key: "digital_solutions",
  }
]

export const media = [
  
  { data: "Media Coverage", url: "/media-coverage" },
  { data: "Press Release", url: "/press-release" },
]

export const aboutUs = [
  { data: "About Us", url: "/about-us" },
  // { data: "Client Testimonials", url: "/client-testimonials" },
  { data: "Awards & Recognition", url: "/award-recognition" },
  { data: "Our Values", url: "/our-values" },
  { data: "Blogs and Vlogs", url: "/blogs-and-vlogs" },
  {
    data: "Videos",
    url: "/videos",
  },
  {
    data: "Adecco Group",
    target: "_blank", 
    external: true,
    url: "https://www.adeccogroup.com/",
  },
  {
    data: "Corporate Social Responsibility",
    url: "/corporate-social-responsibility",
  },
]

export const contactUs = [
  { data: "Contact Us", url: "/contact-us" },
  { data: "Locations", url: "/locations" },
  { data: "PF Help Centre", url: "/PF-help-centre" },
  { data: "FAQ", url: "/faqs" },
]

export const jobSeeker = [
  { 
    data: "Search for Jobs",
    url: "http://jobs.adecco.in/home/jobs",
    target: "_blank", 
    external: true
  },
  { 
    data: "Work for Us",
    url: "https://careers.adeccogroup.com/",
    target: "_blank", 
    external: true
  },
  { data: "Submit Your CV", url: "/submit-your-cv" },
  { data: "Refer a Friend", url: "/refer-a-friend" },
]

export const menu = [
  { title: "Solutions", data: [...solutions], key: "/solutions" },
  { title: "Media", data: [...media], key: "/media" },
  { title: "About us", data: [...aboutUs], key: "/about_us" },
  { title: "Contact us", data: [...contactUs], key: "/contact_us" },
  { title: "Job Seeker", data: [...jobSeeker], key: "/job_seeker" },
]

export const headerText = {
  clientHub: "Client Hub",
  associateHub: "Associate Hub",
  solutions: "Solutions",
  media: "Media",
  contactUs: "Contact Us",
  aboutUs: "About Us",
  jobSeeker: "Job Seeker",
  adeccoEmail: "ASC@adecco.co.in",
  phoneNumber:"18004199233",
  supportTitle: "For Support:",
  businessEnqiury: "Business Enquiry",
  downloadOurApp: "Download Our App",
}
