import React from "react"
import { Row } from "antd"
import MediaCard from "./MediaCard"

const MediaCardLayout = (props: any) => {
  return (
    <>
      <Row type="flex" justify="center" className="pt-2 c-white">
        <Row type="flex" justify="center" className="pr-md-2 pr-0 w-100 w-md-auto">
          <MediaCard
            data={props.data[0]}
            index={0}
            onItemClick={props.onItemClick}
          />
        </Row>
        <Row type="flex" justify="center">
          <Row>
            <MediaCard
              data={props.data[1]}
              index={1}
              onItemClick={props.onItemClick}
            />
            <MediaCard
              data={props.data[2]}
              index={2}
              onItemClick={props.onItemClick}
            />
          </Row>
          <Row>
            <MediaCard
              data={props.data[3]}
              index={3}
              onItemClick={props.onItemClick}
            />
            <MediaCard
              data={props.data[4]}
              index={4}
              onItemClick={props.onItemClick}
            />
          </Row>
        </Row>
      </Row>
    </>
  )
}

export default MediaCardLayout
