import React from "react";
import StaffingExpertiseScreen from "../components/StaffingExpertise/StaffingExpertiseScreen";
import { staffingExpertiseData } from "../../services/staffingExpertiseData";
import PageContent from "../components/PageContent/PageContent";

const StaffingExpertise = ({location}) => {
    const activeKey = location.hash ? location.hash.substring(1) : "";
    return (
        <PageContent class="bg-white py-5 mt-8 " location={location}>
            <StaffingExpertiseScreen pageData = {staffingExpertiseData} activeKey={activeKey}/>
        </PageContent>
    );
    
}

export default StaffingExpertise;
