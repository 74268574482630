import React from "react"
import { Card, Button, Row, Col } from "antd"

const ApplyWithUsCard = props => {
  return (
    <Card
      className="pl-0 pr-0 pl-md-4 pr-md-7"
      style={{
        boxShadow: "0 10px 16px 0 rgba(0, 0, 0, 0.16)",
        border: "solid 0.0625rem #e0e0e0",
        borderRadius: "8px",
      }}
    >
      <Row type="flex" justify="space-between" align="middle">
        <Col md={{ span: 19 }} xs={{ span: 24 }}>
          <div className="font-h5 fw-600 pb-1">{props.header}</div>
          <div className="lead c-secondary py-1">{props.content}</div>
        </Col>
        <Col
          md={{ span: 5 }}
          xs={{ span: 24 }}
          className="pl-0 pl-md-4 mt-4 mt-md-0"
        >
          <Button type="danger" size="large">
            <a href="http://jobs.adecco.in/home/jobs" target="_blank">
              Search for Jobs
            </a>
          </Button>
        </Col>
      </Row>
    </Card>
  )
}

export default ApplyWithUsCard
