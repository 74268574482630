import React from "react"
import { Row } from "antd"
import { Link } from "gatsby"


export function ProtectYourself({ pageData }) {
  return (
    <React.Fragment>
      <Row type="flex" justify="start">
        <h1 className="heading-4 mb-5">Protect Yourself</h1>
      </Row>
      <Row className="font-medium lh-x-large pb-6">
        <Row dangerouslySetInnerHTML={{__html: pageData.description}}></Row>
        </Row>
    </React.Fragment>
  )
}
