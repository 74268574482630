import { Row, Table } from 'antd';
import React from 'react';
import { cookiesPolicyPageData } from "../../services/CookiePolicyData";
import PageContent from '../components/PageContent/PageContent';

const CookiesPolicy = (props: any) => {
  return (
    <PageContent class="bg-white pt-5">
      <h2 className="fw-600 font-h2">{cookiesPolicyPageData.heading}</h2>
      <Row
        className="fw-500 font-normal p-2"
        dangerouslySetInnerHTML={{
          __html: cookiesPolicyPageData.cookieDetails1,
        }}
      ></Row>
      <div className="px-3" style={{width: "100%", overflow: "auto"}}>
        <Table
          dataSource={cookiesPolicyPageData.dataSource}
          columns={cookiesPolicyPageData.columns}
          pagination={false}
          bordered
        />
      </div>
      <Row
        className="fw-500 font-normal p-2"
        dangerouslySetInnerHTML={{
          __html: cookiesPolicyPageData.cookieDetails2,
        }}
      ></Row>
    </PageContent>
  )
}

export default CookiesPolicy;
