import React, { useState } from "react"
import { Row, Col } from "antd"
import { blogVideoData } from "../../services/MediaData"
import MediaCardLayout from "../components/MediaCardLayout/MediaCardLayout"
import VideoModal from "../components/VideoModal/VideoModal"
import { PageContentFullWidth } from "../components/PageContent/PageContent"

const BlogsAndVideos = (props) => {
  const [showVideoModal, changeVisibilityModal] = useState(false)
  const [selectedItem, changeselectedItem]: any = useState({})

  const onItemClick = (item: any) => {
    changeselectedItem(item)
    if (item.type === "video") {
      changeVisibilityModal(true)
    } else {
      window.open(item.link, item.target)
    }
  }
  return (
    <React.Fragment>
    
      {showVideoModal && (
        <VideoModal 
          onCloseModal={() => changeVisibilityModal(false)}
          data={selectedItem}
        />
      )}
      <PageContentFullWidth location={props.location}>
      <Col className="pb-9">
        <Row className="py-5 text-align-center heading-3">Blogs & Vlogs</Row>
        <MediaCardLayout data={blogVideoData} onItemClick={onItemClick} />
      </Col>
    </PageContentFullWidth>
    </React.Fragment>
  )
}

export default BlogsAndVideos
