import React, { useState } from "react"
import { Row, Col } from "antd"
import { WrappedBusinessEnquiryForm } from "../components/Forms/BusinessEnquiryForm"
import PageContent from "../components/PageContent/PageContent"
//@ts-ignore
import businessEnquiryImage from "../images/BusinessEnquiryImage.png"
import { text } from "../constants"

export interface IFormProps {
  location: any
  pageSource: string
}

const BusinessEnquiryForm = (props: IFormProps) => {
  const interested = props.location.state ? props.location.state.interested : ""
  const [interestedInData, setInterestedInData] = useState(
    interested === "" ? "Contact us about working together" : interested
  )

  const changeInterestedArea = (value: string) => {
    setInterestedInData(value)
  }

  const enquiryFrom = () => {
    return (
      <div>
        <Row type="flex" justify="center">
          <h1 className="heading-3 text-align-center">Business Enquiry</h1>
        </Row>
        <Row type="flex" justify="space-around" className="px-4 px-md-0">
          <Col lg={10} md={24} className="d-lg-block d-none">
            <Row className="pos-relative text-align-center d-inline-block align-items-center justify-content-center d-flex">
              <img src={businessEnquiryImage} className="mw-90" />
              <div className="pos-absolute c-pantone fw-600 lh-small font-lg-h5 w-100 px-4">
                {interestedInData}
              </div>
            </Row>
          </Col>
          <Col lg={10} md={24} xs={22}>
            <WrappedBusinessEnquiryForm
              getInterestedArea={changeInterestedArea}
              showInterestedInValue={interested}
              pageSource={props.pageSource}
            />
          </Col>
        </Row>
      </div>
    )
  }
  return props.pageSource === text.DIGITAL_BUSINESS ? (
    <div className="px-7 py-3">{enquiryFrom()}</div>
  ) : (
    <PageContent location={props.location} class="py-5">{enquiryFrom()}</PageContent>
  )
}

export default BusinessEnquiryForm
