const BranchData = {
  title: "Location",
  BranchDetails: [
    {
      title: "Kolkata",
      key: "East India",
      Address:
        "4th Floor, Unit No. - 19, Chowringhee Court 55/1, Chowringhee Road, Kolkata – 700071.",
      TelNo: "+91 33 - 40357070",
    },
    {
      title: "Indore",
      key: "North India",
      Address: `104 Royal Estate Building 9/2, South Tukoganj Opp Sahaj hospital Indore 452001 MP India`,
      TelNo: "+91 9039002804",
    },
    {
      title: "Udaipur",
      key: "North India",
      Address:
        "Shukarana, No. 6 1st floor Durga Nursery Road, Near Sukhadia Memorial, Udaipur - 313001.",
      TelNo: "+91 9071577493",
    },
    {
      title: "Gurgaon",
      key: "North India",
      Address:
        "Unit # 144 & 145, 1st Floor, Centrum Plaza, Sector - 53, Gurgaon, Haryana - 122002.",
      TelNo: "+91 124 - 4543000",
    },
    {
      title: "Mohali",
      key: "North India",
      Address:
        "ST Square Building Plot No C 167, Office no 204-206 First floor, Industrial area, Phase 8 B, SAS Nagar Mohali, PIN 140308",
      TelNo: "+91 172 - 4917000",
    },
    {
      title: "Rudrapur",
      key: "North India",
      Address:
        "No 101, 1st Floor, PSA Plaza, Near Hotel Kanchantara, Nainital Highway, Rudrapur, Uttrakhand-263153",
      TelNo: "+91 5944 - 690009 / 690690",
    },
    {
      title: "Hyderabad",
      key: "South India",
      Address:
        "H.No.8-2-248/1/757/57A, Plot.57, Nagarjuna Hills, Corporation Bank Building, Punjagutta, Hyderabad - 500082, Telangana.",
      TelNo: "+91 40 - 67868800",
    },
    {
      title: "Vizag - US IT",
      key: "South India",
      Address:
        "Softsol Building, First floor – B wing; Plot No. 6, Hill No. 02, Rushikonda, Madhurawada, Visakhapatnam, Andhra Pradesh – 530045",
      TelNo: "+91 891 6676100",
    },
    {
      title: "Bangalore",
      key: "South India",
      Address:
        "No 73/1, 13th Floor, Summit B, Brigade Metropolis, Garudachar Palya, Mahadevapura Post, Whitefield Main Road, Bengaluru – 560048",
      TelNo: "+91 80 - 68407000",
    },
    {
      title: "Chennai",
      key: "South India",
      Address:
        "# 47, Montieth Place, 2nd & 3rd Floor, Montieth Road, Egmore, Chennai - 600008.",
      TelNo: "+91 44 - 71113334",
    },
    {
      title: "Coimbatore",
      key: "South India",
      Address:
        "No: 151 B, Aditya Complex,2nd Floor, Room No: 201, Above Federal Bank, NSR Road, Saibaba colony, Coimbatore - 641011",
      TelNo: "+91 9071577324",
    },
    {
      title: "Trivandrum",
      key: "South India",
      Address:
        "No 11/766-2, Ground Floor, Krishna Tower Building, Plamoodu, Trivandrum – 695004",
      TelNo: "+91 9061026440",
    },
    {
      title: "Mumbai",
      key: "West India",
      Address:
        "1007/1008, 10th Floor, Windfall Building, Sahar Plaza, Andheri-Kurla Road, JB Nagar, Andheri (E), Mumbai – 400 059",
      TelNo: "+91 22 - 48925500",
    },
    {
      title: "Pune",
      key: "West India",
      Address:
        "#Unit No. 16, 17 & 18, 2nd Floor, Aditya Centeegra, FC Road, Shivajinagar, Pune – 411004",
      TelNo: "+91 20 - 66838100",
    },
    {
      title: "Ahmedabad",
      key: "West India",
      Address:
        "B-402, 4th Floor, Sankalp Iconic Tower, Opp Vikram Nagar, Iscon Temple Cross Road, S.G Highway, Ahmedabad-380054.",
      TelNo: "+91 9071378122",
    },
    {
      title: "Baroda",
      key: "West India",
      Address:
        "1st Floor, Nitya Building, Near Radha Krishna Cross Road, Akota, Vadodara-390020.",
      TelNo: "+91 9033009207",
    },
    {
      title: "Goa",
      key: "West India",
      Address:
        "2G, 2nd floor, Building no 4, Sesa Ghor , Plot No -20, Patto, Panaji, Goa – 403001.",
      TelNo: "+91 9028002043 / 9028001690",
    },
  ],
}

export default BranchData
