import React, { useState } from "react"
import CookieConsent from "react-cookie-consent"
import ModalComponent from "../ModalComponent/Modal"
import {CookiePolicyData} from "../../../services/CookiePolicyData"

const Cookie = () => {

  const [modalVisibility, setModalVisibility] = useState()

  const handleModalView = () => {
    setModalVisibility(true)
  }

  const changeModalView = (value: boolean) => {
    setModalVisibility(value)
  }

  
  return (
    <React.Fragment>
      <CookieConsent
        location="bottom"
        enableDeclineButton
        buttonText="I Accept"
        declineButtonText="I Reject"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonClasses="bg-white c-primary"
        declineButtonClasses="bg-white c-primary"
        expires={150}
      >
        We use cookies to personalise content and ads, to provide social media
        features and to analyse our traffic. We also disclose information about
        your use of our site with our social media, advertising and analytics
        partners. Additional details are available in our <a onClick={handleModalView} className="c-info">cookie policy</a>.
        {modalVisibility && (
            <ModalComponent
              title= "Cookie Policy"
              isVisible={modalVisibility as boolean}
              getValueModal={changeModalView}
              modalData={CookiePolicyData(location.pathname)}
              url={location.pathname}
            />
          )}
      </CookieConsent>
    </React.Fragment>
  )
}

export default Cookie
