import React, { useState } from "react";
import { Row, Button } from "antd";
import { Link } from "gatsby";

const DetailScreen = ({details}) => {
   
    return(
        <React.Fragment>
            <Row className="px-0 px-md-6 ">
                <Row className="pb-md-5 pb-3 pt-7 pt-md-4">
                    <img src={details.detailImg}  className=" pr-3"/>
                     <span className="font-h6 fw-600">{details.title}</span>
                </Row><hr style={{opacity: "0.6"}}/>
                <Row className="font-medium pt-5 pb-5 text-align-justify lh-x-large" style={{opacity: 0.9, overflowWrap: "break-word"}}>
                   <Row  dangerouslySetInnerHTML={{ __html:details.description}}></Row>
                   
                </Row>
                <Row type="flex" justify="center">
                    <Link to={`/business-enquiry/`} state={{interested:details.title}}> 
                    <Button type="danger" className="font-normal c-white" size="large">Business Enquiry</Button>
                    </Link>
                </Row>
            </Row>
        </React.Fragment>
    )
}

export default DetailScreen;