const homepageData = {
  home: [
    {
      component_type: "page_header_text",
      text: {
        content: "Delivering the right workforce solutions, right on time",
      },
    },
    {
      component_type: "hero_image",
      img_url:
        "https://cdn.zeplin.io/5dc2cc76ba70205737335bd8/assets/56882E62-1064-4977-8E98-E320AD11ED17.png",
    },
  ],

  home_screen: [
    {
      component_type: "page_header_text",
      text: {
        content: "Welcome to Adecco India",
      },
    },
    {
      component_type: "sub_header_text",
      text: {
        content: "Making the future work for everyone",
      },
    },
    {
      component_type: "description_text",
      text_content: `We are a Fortune 500 Global organization, and the world’s leading HR Solutions partner.  Our vision is to make the future work for you!`,
    },
  ],

  dream_job: [
    {
      component_type: "page_header_text",
      text: {
        content: "Find Your Dream Job",
      },
    },
    {
      component_type: "sub_header_text",
      text: {
        content: "Create rewarding careers",
      },
    },
    {
      component_type: "description_text",
      text_content: `We help candidates to find employment opportunities, develop their skills, and take their next career steps. Our expertise, digital tools, and network connect people with job opportunities, providing meaningful roles and a stimulating working environment.`,
    },
  ],
  hire_talent: [
    {
      component_type: "page_header_text",
      text: {
        content: "Hire Top Talent",
      },
    },
    {
      component_type: "sub_header_text",
      text: {
        content: "Delivering the right workforce solutions, right on time",
      },
    },
    {
      component_type: "description_text",
      text_content: `We work closely with our clients to address and anticipate their needs, finding and attracting talent with relevant skills. Technology will help us to add value and react to workplace needs efficiently.`,
    },
  ],

  solutions: [
    {
      component_type: "page_header_text",
      text: {
        content: "Solutions",
      },
    },
    {
      component_type: "card_list",
      sub_components: [
        {
          component_type: "card_with_img",
          title: "General Staffing",
          key: "general_staffing",
          img_url: "generalStaffing",
          img_position: "left",
          url: "/staffing-expertise/#general_staffing",
          text_content:
            "As industry pioneers with an extensive branch network, we deliver temporary staffing solutions for almost every sector, including specialist industries",
        },
        {
          component_type: "card_with_img",
          title: "Professional Staffing",
          key: "professional_staffing",
          img_url: "professionalStaffing",
          img_position: "left",
          url: "/staffing-expertise/#professional_staffing",
          text_content:
            "High-end skill fulfilment across mainstream and niche technologies for permanent, temporary and project needs in a fast-paced professional landscape",
        },
        {
          component_type: "card_with_img",
          title: "Permanent Recruitment",
          key: "permanent_recruitment",
          img_url: "permanentRecruitment",
          img_position: "left",
          url: "/staffing-expertise/#permanent_recruitment",
          text_content:
            "Our extensive local and international network and fully digitalised sourcing approach allows us to source and match the right people to the right roles",
        },
        {
          component_type: "card_with_img",
          title: "Outsourcing",
          key: "international_outsourcing",
          img_url: "outsourcing",
          img_position: "left",
          url: "/staffing-expertise/#international_outsourcing",
          text_content:
            "A proven partner you can rely on us for your international sourcing requirements to help drive cost, quality, efficiency and scalability",
        },
        {
          component_type: "card_with_img",
          title: "RPO Lite",
          key: "rpo_lite",
          img_url: "rpoLite",
          img_position: "left",
          url: "/staffing-expertise/#rpo_lite",
          text_content:
            "We manage the entire, or parts of the recruitment process for our clients",
        },
        {
          component_type: "card_with_img",
          title: "International Engagement",
          key: "international_engagement",
          img_url: "internationalEngagement",
          img_position: "left",
          url: "/staffing-expertise/#international_engagement",
          text_content:
            "Our global network and connected approach provides customers with the convenience, simplicity and efficiency of working with one trusted HR solutions partner",
        },
        {
          component_type: "card_with_img",
          title: "Hire Train Deploy",
          key: "hire_train_deploy",
          img_url: "hireTrainDeploy",
          img_position: "left",
          url: "/staffing-expertise/#hire_train_deploy",
          text_content:
            "We hire the right candidate, provide customized training and deploy the resources once the training is over",
        },
       
        {
          component_type: "card_with_img",
          title: "Talent Development & Career Transition",
          key: "lhh",
          img_url: "talentDevelopment",
          img_position: "left",
          url: "/staffing-expertise/#lhh",
          text_content:
            "Integrated solutions to accelerate workforce transformation, talent development, career counselling, outplacement services and leadership counselling",
        },
        {
          component_type: "card_with_img",
          title: "MSP & RPO",
          key: "pontoon",
          img_url: "mspRpo",
          img_position: "left",
          url: "/staffing-expertise/#pontoon",
          text_content:
            "We can work with you to evaluate existing processes and tailor solutions for an optimized supply base",
        },
        {
          component_type: "card_with_img",
          title: "Digital Solutions",
          key: "digital_solutions",
          img_url: "digitalSolution",
          img_position: "left",
          url: "/staffing-expertise/#digital_solutions",
          text_content: "Traditional lines across industries are getting blurred in a smart, connected, and efficient world. Technology is taking the world by storm, and traditional businesses are getting re-engineered digitally. "
        }
      ],
    },
    {
      component_type: "go_to_top",
      img_url: "img_url",
      title: "Top",
    },
  ],

  staffing: [
    {
      component_type: "page_header_text",
      text: {
        content: "Get our staffing expertise in your speciality area",
      },
    },
    {
      component_type: "hero_image",
      img_url: "",
    },
    {
      component_type: "card_list",
      sub_components: [
        {
          component_type: "card_with_img",
          title: "Telecom and Subsidiaries",
          img_url: "img_url",
          img_position: "left",
          text_content: "",
        },
        {
          component_type: "card_with_img",
          title: "Oil and Gas",
          img_url: "img_url",
          img_position: "left",
          text_content: "",
        },
        {
          component_type: "card_with_img",
          title: "Healthcare",
          img_url: "img_url",
          img_position: "left",
          text_content: "",
        },
      ],
    },
    {
      component_type: "go_to_top",
      img_url: "img_url",
      title: "Top",
    },
  ],

  values: [
    {
      component_type: "page_header_text",
      text: {
        content: "What we bring to you",
      },
    },
    {
      component_type: "hero_image",
      img_url: "",
    },
    {
      component_type: "card_list",
      sub_components: [
        {
          component_type: "card_with_img",
          title: "Our Values",
          img_url: "img_url",
          img_position: "top",
          text_content: "",
        },
        {
          component_type: "card_with_img",
          title: "Adecco way to work",
          img_url: "img_url",
          img_position: "top",
          text_content: "",
        },
        {
          component_type: "card_with_img",
          title: "Adecco support center",
          img_url: "img_url",
          img_position: "top",
          text_content: "",
        },
      ],
    },
    {
      component_type: "go_to_top",
      img_url: "img_url",
      title: "Top",
    },
  ],

  testimonial: [
    {
      component_type: "card_with_img",
      name: "Vini Somnath",
      position: "National Engineering Industries Ltd",
      text_content:
        "I am dealing with Adecco for more than 2 years and really happy to share, that I feel happy anytime dealing with the Adecco representatives. The need is well understood by them and the response time is really good. Well done team Adecco !!"
    }
  ],

  about_us: [
    {
      component_type: "page_header_text",
      text: {
        content: "About Us",
      },
    },
    {
      component_type: "hero_image",
      img_url: "",
    },
    {
      component_type: "description_text",
      text_content: "<p>The world of work and employment is continually evolving, and the Adecco Group is one of the leading forces shaping that change. With our breadth and depth of expertise gained on the global stage, we’re able to deliver fully integrated workforce solutions that will support your organization’s development and growth strategy. So you’re not just ready for the future. You’re already there.</p><p>Our ecosystem of brands and solutions allows us to offer complete solutions that help solve the talent challenges faced by our clients and to support our candidates and associates throughout their careers. We are embracing the potential of digital, adding more value to existing solutions, expanding our market, and significantly improving efficiency. We see digitization as an opportunity to grow employment. By combining the best of the HR solutions industry with the best of the technology industry in a responsible way, we seek to become a more valuable service partner.</p><p>Our India office, headquartered in Bengaluru captures a unique job market, and each year helps over 120000 candidates across more than 55 cities and towns to find the perfect job.</p>",
    },
    {
      component_type: "img_list",
      sub_components: [
        {
          component_type: "img_small",
          img_url: "img_url",
        },
        {
          component_type: "img_small",
          img_url: "img_url",
        },
        {
          component_type: "img_small",
          img_url: "img_url",
        },
        {
          component_type: "img_small",
          img_url: "img_url",
        },
        {
          component_type: "img_small",
          img_url: "img_url",
        },
      ],
    },
    {
      component_type: "go_to_top",
      img_url: "img_url",
      title: "Top",
    },
  ],

  footer: [
    {
      component_type: "footer_content",
      title: "Know Us",
      list: [
        { data: "About Us", url: "/about-us", key: "about_us" },
        { data: "Locations", url: "/locations", key: "locations" },
        {
          data: "Privacy Policy",
          url: "/privacy-policy",
          key: "privacy_policy",
        },
        { data: "Security", url: "/security", key: "security" },
        { data: "Contact Us", url: "/contact-us", key: "contact_usk" },
        { data: "Cookie Policy", url: "/cookie-policy", key: "" },
      ],
    },
    {
      component_type: "footer_content",
      title: "Solutions",
      list: [
        {
          data: "General Staffing",
          url: "/staffing-expertise/#general_staffing",
          key: "general_staffing",
        },
        {
          data: "Professional Staffing",
          url: "/staffing-expertise/#professional_staffing",
          key: "professional_staffing",
        },
        {
          data: "Permanent Recruitment",
          url: "/staffing-expertise/#permanent_recruitment",
          key: "permanent_recruitment",
        },
        {
          data: "Outsourcing",
          url: "/staffing-expertise/#international_outsourcing",
          key: "outsourcing",
        },
        {
          data: "RPO Lite",
          url: "/staffing-expertise/#rpo_lite",
          key: "rpo_lite",
        },
        {
          data: "International Engagement",
          url: "/staffing-expertise/#international_engagement",
          key: "international_engagement",
        },
      ],
    },
    {
      component_type: "footer_content",
      title: "",
      list: [
        
        {
          data: "Hire Train Deploy",
          url: "/staffing-expertise/#hire_train_deploy",
          key: "hire_train_deploy",
        },
        
        {
          data: "Talent Development & Career Transition",
          url: "/staffing-expertise/#lhh",
          key: "lhh",
        },
        {
          data: "MSP & RPO",
          url: "/staffing-expertise/#pontoon",
          key: "pontoon",
        },
        {
          data: "Digital Solutions",
          url: "/staffing-expertise/#digital_solutions",
          key: "digital_solutions"
        }
      ],
    },
    {
      component_type: "footer_content",
      title: "Others",
      list: [
        { data: "Refer a Friend", url: "/refer-a-friend", key: "" },
        { data: "FAQs", url: "/faqs", key: "" },
        { data: "Blogs", url: "/blogs-and-vlogs", key: "" },
        { data: "Videos", url: "/videos", key: "" },
        { data: "Media Coverage", url: "/media-coverage", key: ""},
        { data: "Press Release", url: "/press-release", key: "" },
      ],
    },
  ],
}

export default JSON.parse(JSON.stringify(homepageData))
