import React from "react"
import { Row, Col } from "antd"
import referAFriendIcon from "../images/ReferAFriend.png"
import { WrappedReferAFriendForm } from "../components/Forms/ReferAFriendForm"
import PageContent from "../components/PageContent/PageContent"

function referAFriend(props) {
  return (
    <PageContent location={props.location}>
      <Row type="flex" justify="center">
        <h1 className="heading-3 pt-5 mb-4">Refer A Friend</h1>
      </Row>
      <Row
        type="flex"
        justify="space-around"
        className="px-4 px-md-0"
        align="middle"
      >
        <Col xs={{ span: 24 }} md={{ span: 9 }}>
          <Row>
            <img src={referAFriendIcon} className="mw-100 d-lg-block d-none" />
          </Row>
        </Col>
        <Col span={24}  lg={{ span: 10 }} className="px-md-0 px-4">
          <WrappedReferAFriendForm />
        </Col>
      </Row>
    </PageContent>
  )
}

export default referAFriend
