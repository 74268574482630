import React from "react"
import { Row, Col, Button } from "antd"
import { Link } from "gatsby"
import "./HomePageStyle.less"
import HomeScreenImg from "../../images/homescreen.png"

const HomeScreen: React.FC<{ pageData: any }> = ({ pageData }) => {
  return (
    <Row
      type="flex"
      align="middle"
      justify="center"
      className="p-6 p-md-9 p-lg-0"
    >
      <Col md={24} lg={10} className="d-none d-lg-block pt-7">
        <img src={HomeScreenImg} alt="home image" className="mw-100" />
      </Col>
      <Col md={24} lg={9}>
        {pageData &&
          pageData.map((item: any) => {
            if (item.component_type === "page_header_text") {
              return <h1 className="heading-1 c-white">{item.text.content}</h1>
            }
            if (item.component_type === "sub_header_text") {
              return (
                <h2 className="heading-4 c-white ls-1">{item.text.content}</h2>
              )
            }
            if (item.component_type === "description_text") {
              return (
                <h3 className="c-white fw-600 ls-1" style={{ lineHeight: "2" }}>
                  {item.text_content}
                </h3>
              )
            }
          })}
        <Row className="pt-4">
          <Col md={24} lg={10} className="pr-xs-3 pb-2">
            <Button className="c-primary w-100 fw-600 h-6" size="large">
              <Link to={`/business-enquiry/`} state={{ interested: "" }}>
                Business Enquiry
              </Link>
            </Button>
          </Col>
          <Col md={24} lg={10} className="pr-xs-3 pb-2">
            <Button type="default" className="w-100 fw-600 h-6" size="large">
              <a href="http://jobs.adecco.in/home/jobs" target="_blank">
                Search for Jobs
              </a>
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default HomeScreen
