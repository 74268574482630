import React from "react"
import "./StickyBar.less"
// @ts-ignore
import fbIcon from "../../images/fbIcon.png"
// @ts-ignore
import twitterIcon from "../../images/twitterIcon.png"
// @ts-ignore
import linkedinIcon from "../../images/linkedinIcon.png"
// @ts-ignore
import youtubeIcon from "../../images/youtubeIcon.png"
// @ts-ignore
import instagramIcon from "../../images/instagramIcon.png"

const socialMediaOptions = [
  { mediaImage: fbIcon, mediaLink: "https://www.facebook.com/AdeccoIN/" },
  { mediaImage: twitterIcon, mediaLink: "https://twitter.com/adeccoin" },
  {
    mediaImage: linkedinIcon,
    mediaLink: "https://www.linkedin.com/company/adecco/",
  },
  {
    mediaImage: youtubeIcon,
    mediaLink:
      "https://www.youtube.com/channel/UCeBl4bhbEacnglxipHz6sAQ?view_as=subscriber",
  },
  {
    mediaImage: instagramIcon,
    mediaLink: " https://www.instagram.com/adeccoin/?hl=en",
  },
]

const StickyBar = () => {
  return (
    <div className="media-bar">
      {socialMediaOptions.map(socialMedia => (
        <a
          href={socialMedia.mediaLink}
          style={{ width: "2.125rem" }}
          target="_blank"
        >
          <img
            className="media-style"
            src={socialMedia.mediaImage}
            alt="social media icons"
          />
        </a>
      ))}
    </div>
  )
}

export default StickyBar
