import award from "../src/images/awardImage.jpg"
import award2 from "../src/images/award2.png"

const AwardRecognitionData = [
    {
        img: award,
        title: "11th most attractive company in the world to work for",
        content: "Adecco has been named No.11 company on the 2019 list of the World’s Best Workplaces by Great Place to Work. To qualify for consideration in this year’s ranking, companies must appear on at least five of Great Place to Work’s national Best Workplaces lists, which are published with media partners in 58 countries and territories, and also must employ more than 5,000 workers globally. More than 8,000 organizations participated in the survey, representing the voices of more than 12 million workers worldwide."
    },
    // {
    //     img: award2,
    //     title: "Adecco Group ranked 7th among the best multinationals",
    //     content: "In January 2017, Adecco Canada was Certified as a Great Workplace—an official designation awarded by the Great Place to Work® Institute to organizations meeting the highest standard of workplace culture based on an analysis of employee feedback, as well as an assessment of workplace practices and programs."
    // },
    // {
    //     img: award1,
    //     title: "Fortune 100 best companies to work for 2019",
    //     content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi tincidunt, felis ut convallis euismod, libero tellus porttitor ipsum, a interdum purus urna sit amet enim. Curabitur ut justo ut felis aliquam congue. Suspendisse ac pharetra sem, ac laoreet enim. Nulla sem purus, hendrerit a cursus a, vehicula sed tortor. Sed porta leo in magna hendrerit condimentum. Aliquam vel vestibulum lectus. Vestibulum congue mauris erat. Aenean eget sem in mi pellentesque feugiat. Vestibulum vitae sem elit."
    // },
    // {
    //     img: award2,
    //     title: "Adecco is recognized as Great Place to Work® a third time!",
    //     content: "On April 26, 2017, The Adecco Group - along with its specialty brands Adecco, Adecco Professional and Roevin Engineering & Technology - was again recognized on the Top 50 list for Large and Multinational organizations.The Great Place to Work Institute received over 400 nominations and participation from more than 60,000 employees – making it the definitive list of exceptional work environments in Canada and Adecco’s placement on the Top 50 that much more impressive. "
    // },
    // {
    //     img: award1,
    //     title: "Adecco Group ranked 7th among the best multinationals",
    //     content: "In January 2017, Adecco Canada was Certified as a Great Workplace—an official designation awarded by the Great Place to Work® Institute to organizations meeting the highest standard of workplace culture based on an analysis of employee feedback, as well as an assessment of workplace practices and programs."
    // },
    // {
    //     img: award2,
    //     title: "Adecco is recognized as Great Place to Work® a third time!",
    //     content: "On April 26, 2017, The Adecco Group - along with its specialty brands Adecco, Adecco Professional and Roevin Engineering & Technology - was again recognized on the Top 50 list for Large and Multinational organizations.The Great Place to Work Institute received over 400 nominations and participation from more than 60,000 employees – making it the definitive list of exceptional work environments in Canada and Adecco’s placement on the Top 50 that much more impressive. "
    // },
    // {
    //     img: award1,
    //     title: "Adecco Group ranked 7th among the best multinationals",
    //     content: "In January 2017, Adecco Canada was Certified as a Great Workplace—an official designation awarded by the Great Place to Work® Institute to organizations meeting the highest standard of workplace culture based on an analysis of employee feedback, as well as an assessment of workplace practices and programs."
    // },
    // {
    //     img: award2,
    //     title: "Fortune 100 best companies to work for 2019",
    //     content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi tincidunt, felis ut convallis euismod, libero tellus porttitor ipsum, a interdum purus urna sit amet enim. Curabitur ut justo ut felis aliquam congue. Suspendisse ac pharetra sem, ac laoreet enim. Nulla sem purus, hendrerit a cursus a, vehicula sed tortor. Sed porta leo in magna hendrerit condimentum. Aliquam vel vestibulum lectus. Vestibulum congue mauris erat. Aenean eget sem in mi pellentesque feugiat. Vestibulum vitae sem elit."
    // },
    // {
    //     img: award1,
    //     title: "Adecco is recognized as Great Place to Work® a third time!",
    //     content: "On April 26, 2017, The Adecco Group - along with its specialty brands Adecco, Adecco Professional and Roevin Engineering & Technology - was again recognized on the Top 50 list for Large and Multinational organizations.The Great Place to Work Institute received over 400 nominations and participation from more than 60,000 employees – making it the definitive list of exceptional work environments in Canada and Adecco’s placement on the Top 50 that much more impressive. "
    // },
    // {
    //     img: award2,
    //     title: "Adecco Group ranked 7th among the best multinationals",
    //     content: "In January 2017, Adecco Canada was Certified as a Great Workplace—an official designation awarded by the Great Place to Work® Institute to organizations meeting the highest standard of workplace culture based on an analysis of employee feedback, as well as an assessment of workplace practices and programs."
    // },
]

export default AwardRecognitionData