import React, { useState } from "react"
import { Button } from "antd"

const Disclaimer = (props: {
  title: string
  isVisible: boolean
  getValueDisclaimer: (value: boolean) => void
  disclaimerData: string
  url?: string
  getValueButton: (value: boolean) => void
}) => {
  const [visibility, setVisibility] = useState(props.isVisible)

  const handleVisibility = () => {
    props.getValueDisclaimer(false)
    setVisibility(false)
    props.getValueButton(true)
  }

  return visibility ? (
    <div>
      <div
        className="pos-fixed bg-white px-6 py-4"
        style={{ bottom: "0", zIndex: 5000, overflowY: "auto", maxHeight: "100%" }}
      >
        <h3 style={{ color: "red" }}>{props.title}</h3>
        <p className="font-xsmall" dangerouslySetInnerHTML={{ __html: props.disclaimerData }}></p>
        <Button key="submit" type="primary" onClick={handleVisibility}>
          OK
        </Button>
      </div>
    </div>
  ) : null
}

export default Disclaimer
