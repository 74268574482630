import React from "react";
import { Row } from "antd";
import { Link } from "gatsby";

export function ContactUs({ pageData }) {
    return (
        <React.Fragment>
            <Row type="flex" justify="start">
                <h1 className="heading-4 mb-5">
                    Contact Us
                </h1>
            </Row>
            <Row className="font-medium lh-x-large" dangerouslySetInnerHTML={{__html: pageData.description}}>
            </Row>
        </React.Fragment >
    );
}