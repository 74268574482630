import React, { useState, useEffect, Fragment } from "react"
import "./Header.less"
import { Menu, Dropdown, Icon, Button, Row, Collapse, Col } from "antd"

import { Link } from "gatsby"
import {
  aboutUs,
  contactUs,
  solutions,
  media,
  menu,
  headerText,
  jobSeeker,
} from "../../../services/HeaderData"
// TODO remove @ts-ignore
// @ts-ignore
import phoneImage from "../../images/phone.svg"
// @ts-ignore
import mailImage from "../../images/mail.svg"
// @ts-ignore
import AdeccoLogo from "../../images/AdeccoLogo.png"
// @ts-ignore
// import AdeccoSafeRestartLogo from "../../images/AdeccoSafeRestartLogo.png"
import MenuItem from "antd/lib/menu/MenuItem"

const { Panel } = Collapse
const renderMenu = data => (
  <Menu>
    {data.map((aboutUsData, index) => {
      return (
        <Menu.Item key={index}>
          {aboutUsData.external ? (
            <a href={aboutUsData.url} target={aboutUsData.target || ""}>
              {aboutUsData.data.split("\n").map((menuItem, index) => {
                return (
                  <Fragment>
                    {menuItem}
                    <br />
                  </Fragment>
                )
              })}
            </a>
          ) : (
            <Link to={aboutUsData.url} target={aboutUsData.target || ""}>
              {aboutUsData.data.split("\n").map((menuItem, index) => {
                return (
                  <Fragment>
                    {menuItem}
                    <br />
                  </Fragment>
                )
              })}
            </Link>
          )}
        </Menu.Item>
      )
    })}
  </Menu>
)

const RenderCollapsedMenu = ({ data, onClose }: any) => {
  useEffect(() => {
    document.body.style.overflow = "hidden"
    return () => {
      document.body.style.overflow = "unset"
    }
  }, [])
  return (
    <div
      className="vh-100 vw-100 pos-fixed bg-white"
      style={{ right: 0, top: 0, zIndex: 1200, overflow: "scroll" }}
    >
      <Row className="p-3" justify="end" type="flex" onClick={() => onClose()}>
        <Icon type="close" />
      </Row>
      <Row
        justify="space-around"
        type="flex"
        className="flex-column align-items-center"
      >
        <Col>
          <Button type="primary" className="justify-content-center vw-60 mt-2">
            <a href={process.env.GATSBY_CLIENT_HUB} target="_blank">
              {headerText.clientHub}
            </a>
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            className="justify-content-center vw-60 mt-2 mb-2"
          >
            <a href={process.env.GATSBY_ASSOCIATE_HUB} target="_blank">
              {headerText.associateHub}
            </a>
          </Button>
        </Col>
      </Row>
      <Collapse accordion expandIconPosition="right" className="collapse mt-2">
        {data.map((menuData, index) => (
          <Panel header={menuData.title} key={index}>
            <Menu style={{ background: "rgb(220,220,220)" }}>
              {menuData.data.map(data =>
                data.data !== undefined || data ? (
                  <MenuItem
                    onClick={() => {
                      location.pathname == "/staffing-expertise/"
                        ? onClose()
                        : ""
                    }}
                  >
                    {data.external ? (
                      <a
                        href={data.url}
                        target={data.target ? data.target : "_parent"}
                      >
                        {data.data}
                      </a>
                    ) : (
                      <Link
                        to={data.url}
                        target={data.target ? data.target : "_parent"}
                      >
                        {data.data}
                      </Link>
                    )}
                  </MenuItem>
                ) : (
                  <></>
                )
              )}
            </Menu>
          </Panel>
        ))}
      </Collapse>
    </div>
  )
}

const HeaderComponent = () => {
  const [renderResponsiveMenu, setRenderResponsiveMenu] = useState(false)
  return (
    <div className="w-100 pos-fixed" style={{ top: 0, zIndex: 1200 }}>
      <Row
        className="pl-md-6 pl-2 bg-charcol h-5"
        type="flex"
        align="middle"
        justify="start"
      >
        <span className="c-white font-xsmall fw-600">
          {headerText.supportTitle}
        </span>
        <img src={phoneImage} className="mw-20 pr-1 pl-2" />
        <span className=" c-white font-xsmall" style={{ opacity: "0.8" }}>
          <a href={`tel:${headerText.phoneNumber}`} className="c-white">
            {headerText.phoneNumber}
          </a>
        </span>
        <a href="mailto:ASC@adecco.co.in" target="_blank">
          <img src={mailImage} className="mw-20 pr-1 pl-2" />
          <span className=" c-white font-xsmall" style={{ opacity: "0.8" }}>
            {headerText.adeccoEmail}
          </span>
        </a>
      </Row>
      <Row
        className="bg-primary py-2 px-md-6 px-2"
        type="flex"
        align="middle"
        justify="space-between"
      >
        <Link to="/">
          {" "}
          <img src={AdeccoLogo} height={25} />
        </Link>
        <div className="d-flex d-lg-none">
          <div>
            {renderResponsiveMenu ? (
              <RenderCollapsedMenu
                onClose={() => setRenderResponsiveMenu(!renderResponsiveMenu)}
                data={menu}
              />
            ) : (
              <></>
            )}
          </div>
          <Button
            type="link"
            icon="menu"
            shape="circle"
            className="c-white"
            onClick={() => setRenderResponsiveMenu(!renderResponsiveMenu)}
          ></Button>
        </div>
        <Row
          className="d-lg-flex d-none"
          type="flex"
          align="middle"
          justify="end"
        >
          <Row type="flex" align="middle">
            <Dropdown
              overlay={renderMenu(solutions)}
              trigger={["hover"]}
              overlayStyle={{ zIndex: 2000 }}
            >
              <a className="ant-dropdown-link px-4">
                <span className="c-white font-normal pr-1">
                  {headerText.solutions}
                </span>
                <Icon className="c-white font-xxsmall" type="down" />
              </a>
            </Dropdown>
            <Dropdown
              overlay={renderMenu(media)}
              trigger={["hover"]}
              overlayStyle={{ zIndex: 2000 }}
            >
              <a className="ant-dropdown-link px-4">
                <span className="c-white font-normal pr-1">
                  {headerText.media}{" "}
                </span>
                <Icon className="c-white font-xxsmall" type="down" />
              </a>
            </Dropdown>
            <Dropdown
              overlay={renderMenu(aboutUs)}
              trigger={["hover"]}
              overlayStyle={{ zIndex: 2000 }}
            >
              <a className="ant-dropdown-link px-4">
                <span className="c-white font-normal pr-1">
                  {headerText.aboutUs}
                </span>
                <Icon className="c-white font-xxsmall" type="down" />
              </a>
            </Dropdown>
            <Dropdown
              overlay={renderMenu(contactUs)}
              trigger={["hover"]}
              overlayStyle={{ zIndex: 2000 }}
            >
              <a className="ant-dropdown-link px-4">
                <span className="c-white font-normal pr-1">
                  {headerText.contactUs}
                </span>
                <Icon className="c-white font-xxsmall" type="down" />
              </a>
            </Dropdown>
            <Dropdown
              overlay={renderMenu(jobSeeker)}
              trigger={["hover"]}
              overlayStyle={{ zIndex: 2000 }}
            >
              <a className="ant-dropdown-link px-4">
                <span className="c-white font-normal pr-1">
                  {headerText.jobSeeker}
                </span>
                <Icon className="c-white font-xxsmall" type="down" />
              </a>
            </Dropdown>
            <Button className="pr-4">
              <a
                href={
                  process.env.GATSBY_CLIENT_HUB ||
                  "https://dev.d2rejzpb8bh7j.amplifyapp.com/"
                }
                target="_blank"
              >
                {headerText.clientHub}
              </a>
            </Button>
            <Button className=" mx-4">
              {/* //TODO: change in env variables */}
              <a href={process.env.GATSBY_ASSOCIATE_HUB} target="_blank">
                {headerText.associateHub}
              </a>
            </Button>
          </Row>
        </Row>
      </Row>
    </div>
  )
}

export default HeaderComponent
