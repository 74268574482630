import React from "react"
import { Col, Row } from "antd"
import HeaderComponent from "../Header/Header"
import StickyBar from "../StickyBar/StickyBar"
import Cookie from "../Cookie/Cookie"
import Footer from "../Footer/Footer"
import data from "../../../services/homepageData"
import CovidRestart from "../CovidRestart/CovidRestart"
import SEO from "../seo"

const PageContent = props => {
  return (
    <React.Fragment>
      <SEO location={props.location} />
      <Row>
        <HeaderComponent />
      </Row>
      {/* <CovidRestart /> */}
      <StickyBar />
      <Row
        type="flex"
        justify="center"
        style={{ marginTop: "70px" }}
        className={props.class}
      >
        <Col span={24} lg={20} md={22} className="px-xs-2 pb-9">
          {props.children}
        </Col>
      </Row>
      <Footer pageData={data.footer} />
      <Cookie />
    </React.Fragment>
  )
}

export default PageContent

export const PageContentFullWidth = props => {
  return (
    <React.Fragment>
      <SEO location={props.location} />
      <Row>
        <HeaderComponent />
      </Row>
      {/* <CovidRestart /> */}
      <StickyBar />
      <Row
        type="flex"
        justify="center"
        style={{ marginTop: "70px" }}
        className={props.class}
      >
        <Col span={24}>{props.children}</Col>
      </Row>
      <Footer pageData={data.footer} />
      <Cookie />
    </React.Fragment>
  )
}

export const PageContentHomeScreen = props => {
  return (
    <React.Fragment>
      <SEO location={props.location} />
      <Row>
        <HeaderComponent />
      </Row>
      {/* <CovidRestart /> */}
      <StickyBar />
      <Row
        type="flex"
        justify="center"
        style={{ marginTop: "70px" }}
        className={props.class}
      >
        <Col span={24}>{props.children}</Col>
      </Row>
      <Cookie />
    </React.Fragment>
  )
}

export const PageContentInnerSection = props => {
  return (
    <Row type="flex" justify="center" className={props.class}>
      <Col span={24} lg={20} md={22} className="px-2 px-md-0">
        {props.children}
      </Col>
    </Row>
  )
}
