import React, { Fragment } from "react"
import { Row, Col, Button } from "antd"
//@ts-ignore
import facebookIcon from "../../images/facebook.png"
//@ts-ignore
import twitterIcon from "../../images/twitter.png"
//@ts-ignore
import linkedinIcon from "../../images/linkedin.png"
//@ts-ignore
import youtubeIcon from "../../images/youtube.png"
//@ts-ignore
import instaIcon from "../../images/Instagram.png"
//@ts-ignore
import googlePlay from "../../images/googlePlay.png"
import { Link } from "gatsby"
import { headerText } from "../../../services/HeaderData"

const socialMediaOptions = [
  { mediaImage: facebookIcon, mediaLink: "https://www.facebook.com/AdeccoIN/" },
  { mediaImage: twitterIcon, mediaLink: "https://twitter.com/adeccoin" },
  {
    mediaImage: linkedinIcon,
    mediaLink: "https://www.linkedin.com/company/adecco/",
  },
  {
    mediaImage: youtubeIcon,
    mediaLink:
      "https://www.youtube.com/channel/UCeBl4bhbEacnglxipHz6sAQ?view_as=subscriber",
  },
  {
    mediaImage: instaIcon,
    mediaLink: "https://www.instagram.com/adeccoin/?hl=en",
  },
]

const Footer = ({ pageData }) => {
  return (
    <div className="pos-relative">
      <Row className="px-4 px-md-9 pt-8 p-6 p-md-9 p-lg-0 bg-charcol h-md-auto h-lg-11 pt-lg-6">
        <Col span={24} lg={19}>
          <Row type="flex">
            {pageData &&
              pageData.map((item: any) => {
                return (
                  <Col
                    span={24}
                    md={12}
                    lg={6}
                    className={
                      item && item.title === ""
                        ? " mt-0 mt-md-5 d-flex flex-column align-items-center align-items-md-start"
                        : " d-flex flex-column align-items-center align-items-md-start"
                    }
                  >
                    <div className="font-h6 fw-600 c-white py-2">
                      {item.title}
                    </div>
                    {item.list.map((item: any) => {
                      return (
                        <Fragment>
                          <Link to={item.url} state={{ activeKey: item.key }}>
                            <div
                              className="font-normal c-secondary py-2 pr-0 pr-md-5"
                              style={{ cursor: "pointer" }}
                            >
                              {item.data}
                            </div>
                          </Link>
                        </Fragment>
                      )
                    })}
                  </Col>
                )
              })}
          </Row>
        </Col>
        <Col
          span={24}
          lg={5}
          className="pt-3 pt-md-6 pt-lg-0 pt px-6 px-md-0 text-align-center"
        >
          <Button
            type="primary"
            size="large"
            className="fw-700 w-100 w-lg-auto h-6 px-4"
          >
            <Link to={`/business-enquiry/`} state={{ interested: "" }}>
              {headerText.businessEnqiury}
            </Link>
          </Button>
          <Row>
            <div className="d-flex justify-content-between my-4 w-100">
              {socialMediaOptions.map(socialMedia => (
                <a href={socialMedia.mediaLink} target="_blank">
                  <img
                    src={socialMedia.mediaImage}
                    alt="social media icons"
                    className="mw-100"
                  />
                </a>
              ))}
            </div>
          </Row>
          {/* <div className="d-flex flex-column">
          <div className="c-white fw-600 font-large mb-4 text-align-center">{headerText.downloadOurApp}</div>
          <img src={googlePlay} className="w-100" />
        </div> */}
        </Col>
      </Row>
    </div>
  )
}

export default Footer
