import React from "react"
import { PageContentFullWidth, PageContentInnerSection } from "../components/PageContent/PageContent";
import { ProtectYourself } from "../components/Security/ProtectYourself";
import { SecurityScreen } from "../components/Security/SecurityScreen.tsx";
import { ContactUs } from "../components/Security/ContactUs.tsx";
import { securityData } from "../../services/SecurityData.ts";


function Security(props) {

  return (
    <PageContentFullWidth location={props.location}>

      <PageContentInnerSection class="bg-white min-vh-100 py-5 px-6 w-100">
        <SecurityScreen pageData={securityData.security} />
      </PageContentInnerSection>

      <PageContentInnerSection class="bg-secondary py-8 px-6">
        <ProtectYourself pageData={securityData.protectYourself} />
      </PageContentInnerSection>

      <PageContentInnerSection class="bg-white pt-8 pb-9 px-6">
        <ContactUs pageData={securityData.contactUs} />
      </PageContentInnerSection>

    </PageContentFullWidth>
  );
}

export default Security;

