import React, { Fragment, useState, useEffect } from "react"
import {
  Form,
  Button,
  Checkbox,
  Input,
  Row,
  Cascader,
  Col,
  message,
  Select,
} from "antd"
import FormItem from "antd/lib/form/FormItem"
import gpSIcon from "../../images/gps.svg"
import { TermsConditionData } from "../../../services/termsAndConditionData"
import ModalComponent from "../ModalComponent/Modal"
import {
  checkError,
  disableSubmitCheck,
  characterValidation,
  phoneNumberValidation,
  onlyNumValue,
  emailValidation,
  countryCodeData,
  checkMaxDigitLimit,
  validateFileType,
  validateFileSize,
} from "./FormUtils"
import uuid from "react-uuid"
import Axios from "axios"

const formFields = [
  "employeeName",
  "referralEmail",
  "countryCode",
  "phoneNumber",
  "location",
  "experience",
  "resume",
]

export function SubmitYourCvForm(props: {
  experience: (value: string) => void
  form
}) {
  const [modalVisibility, setModalVisibility] = useState()
  const [checkCV, setCheckCV] = useState(false)
  const [filename, setFilename] = useState("")
  const [isFileUploading, setIsFileUploading] = useState(false)

  const [country, setCountry] = useState("India:+91")
  const [disableSubmit, setDisableSubmit] = useState(true)
  const [emptyForm, setEmptyForm] = useState(true)
  const [agreement, setAgreement] = useState(true)
  var form = props.form
  const { Option } = Select

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    form.validateFields((err: any, values: any) => {
      if (!err) {
        const countryData = values.countryCode.split(":")
        Axios.post(`${process.env.GATSBY_MIDDLEWARE_API}api/EmailTrigger`, JSON.stringify({
          employeeName: values.employeeName,
          referralEmail: values.referralEmail,
          phoneNumber: countryData[1] + values.phoneNumber,
          country: countryData[0],
          location: values.location,
          experience: values.experience,
          agreement: values.agreement.toString(),
          attachment: filename,
          type: "submit_your_cv",
        }))
        // fetch(`${process.env.GATSBY_MIDDLEWARE_API}api/EmailTrigger`, {
        //   method: "POST",
        //   body: JSON.stringify({
        //     employeeName: values.employeeName,
        //     referralEmail: values.referralEmail,
        //     phoneNumber: countryData[1] + values.phoneNumber,
        //     country: countryData[0],
        //     location: values.location,
        //     experience: values.experience,
        //     agreement: values.agreement.toString(),
        //     attachment: filename,
        //     type: "submit_your_cv",
        //   }),
        // })
        message.success("The form has been successfully submitted.")
        setEmptyForm(true)
        form.resetFields()
      } else {
        message.error("We encountered an error while submitting the form.")
      }
    })
  }

  useEffect(() => {
    var errorData = checkError({ formFields, form })
    var { errorInData, fieldCount } = errorData

    if (fieldCount === formFields.length) {
      setEmptyForm(false)
    }
    setDisableSubmit(disableSubmitCheck(errorInData, emptyForm, agreement))
  })

  const handleModalView = () => {
    setModalVisibility(true)
  }

  const changeModalView = (value: boolean) => {
    setModalVisibility(value)
  }

  const checkAgreement = (rule, value, callback) => {
    if (!value) {
      setAgreement(false)
      callback("Please agree the terms and conditions!")
    } else {
      setAgreement(true)
      callback()
    }
  }

  const validateCV = (rule, value, callback) => {
    if (!checkCV) {
      rule.required = checkCV
    } else {
      rule.required = checkCV
      callback("Upload your resume")
    }
  }

  const placeholder = "*"
  const { getFieldDecorator } = form

  const handleChange = e => {
    setCheckCV(e.target.checked)
    // form.validateFields(["resume"], { force: checkCV })
  }

  const getBase64 = file => {
    let reader = new FileReader()
    return new Promise((resolve, reject) => {
      reader.readAsDataURL(file)
      reader.onload = function() {
        let result: any = reader.result
        result = result.split("base64")[1]
        resolve(result)
      }
    })
  }

  const onFileChange = async (data: any) => {
    setIsFileUploading(true)
    let files = data.target.files
    let filenameSplit = files[0].name.split(".")
    if (!validateFileType(filenameSplit[filenameSplit.length - 1])) {
      data.target.value = null
      setIsFileUploading(false)
      return
    }
    if (!validateFileSize(files[0])) {
      data.target.value = null
      setIsFileUploading(false)
      return
    }
    setIsFileUploading(true)
    let filename = `${uuid()}_resume.${filenameSplit[filenameSplit.length - 1]}`
    const base64Format = await getBase64(files[0])
    Axios.post(`${process.env.GATSBY_MIDDLEWARE_API}api/uploadFile`, JSON.stringify({
      file_base_64: base64Format,
      filename: filename,
    }))
    .then(data => {
      setIsFileUploading(false)
      setFilename(filename)
    })
    .catch(err => setIsFileUploading(false))
    // fetch(`${process.env.GATSBY_MIDDLEWARE_API}api/uploadFile`, {
    //   method: "POST",
    //   body: JSON.stringify({
    //     file_base_64: base64Format,
    //     filename: filename,
    //   }),
    // })
    //   .then(data => {
    //     setIsFileUploading(false)
    //     setFilename(filename)
    //   })
    //   .catch(err => setIsFileUploading(false))
  }

  return (
    <Fragment>
      <Form onSubmit={handleSubmit}>
        <FormItem className="mb-2">
          {getFieldDecorator("employeeName", {
            validateTrigger: "onBlur",
            rules: [
              {
                required: true,
                message: "Please enter your name",
              },
              { max: 50, message: "Character limit exceeded" },
              {
                pattern: characterValidation,
                message: "Please enter valid characters",
              },
            ],
          })(<Input size="large" placeholder={"Name" + placeholder} />)}
        </FormItem>
        <FormItem className="mb-2">
          {getFieldDecorator("referralEmail", {
            validateTrigger: "onBlur",
            rules: [
              {
                pattern: emailValidation,
                message: "Please enter a valid email!",
              },
              {
                required: true,
                message: "Please enter your E-mail!",
              },
            ],
          })(<Input size="large" placeholder="Email *" />)}
        </FormItem>
        <Row type="flex">
          <Col span={24} lg={10}>
            <Form.Item className="mb-2 mr-0 mr-md-2">
              {getFieldDecorator("countryCode", {
                validateTrigger: "onBlur",
                initialValue: "India:+91",
                rules: [
                  {
                    required: true,
                    message: "Please select your country code",
                  },
                ],
              })(
                <Select
                  size="large"
                  onChange={(value: string) => {
                    setCountry(value)
                  }}
                >
                  {countryCodeData.map((data, index) => {
                    return (
                      <Option
                        key={index}
                        value={data.country + ":" + data.countryCode}
                      >
                        {data.country + " : " + data.countryCode}
                      </Option>
                    )
                  })}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={24} lg={14}>
            <Form.Item className="mb-2 mr-0 mr-md-2">
              {getFieldDecorator("phoneNumber", {
                validateTrigger: "onBlur",
                rules: [
                  {
                    required: true,
                    message: "Please enter your phone number",
                  },
                  {
                    pattern: phoneNumberValidation,
                    message: "Please enter valid digits!",
                  },
                  {
                    validator: (rule, value, callback) =>
                      checkMaxDigitLimit(rule, value, callback, country),
                  },
                ],
              })(
                <Input
                  size="large"
                  placeholder="Phone Number *"
                  onKeyPress={e => {
                    onlyNumValue(e)
                  }}
                />
              )}
            </Form.Item>
          </Col>
        </Row>

        <FormItem className="mb-2">
          {getFieldDecorator("location", {
            validateTrigger: "onBlur",
            rules: [
              {
                required: true,
                message: "Please enter your location!",
              },
              { max: 250, message: "Character limit exceeded" },
              // {
              //   validator: (rule, value, callback) =>
              //     checkRequiredValue(rule, value, callback, "location"),
              // },
            ],
          })(<Input size="large" placeholder="Current Location*" />)}
        </FormItem>
        <FormItem className="mb-2">
          {getFieldDecorator("experience", {
            validateTrigger: "onBlur",
            rules: [
              {
                required: true,
                message: "Please enter your experience!",
              },
            ],
          })(
            <Select size="large" placeholder="Experience *">
              <Option value="0 - 2 Years">0 - 2 Years</Option>
              <Option value="3 - 5 Years">3 - 5 Years</Option>
              <Option value="6 - 10 Years">6 - 10 Years</Option>
              <Option value="10+ Years">10+ Years</Option>
            </Select>
          )}
        </FormItem>
        <div className="mb-3 c-secondary">Attach Resume </div>

        <Form.Item className="mb-2">
          {getFieldDecorator("resume", {
            rules: [
              {
                required: true,
                message: "Please upload resume!",
              },
            ],
          })(
            <Input
              type="file"
              onChange={onFileChange}
              accept=".pdf,.doc,.docx"
              size="large"
            />
          )}
          {/* {getFieldDecorator("resume", {
                validateTrigger: "onBlur",
                rules: [
                  {
                    required: true,
                    message: "Please upload resume!",
                  },
                  {
                    validator: validateCV,
                  },
                ],
              })(
                // <Upload
                //   multiple={false}
                //   name="logo"
                //   customRequest={getBlobFile}
                //   onChange={onFileChange}
                //   listType="picture"
                // >
                //   <Button
                //     size="large"
                //     className="c-info"
                //     style={{ border: "1px solid #00b6ef" }}
                //   >
                //     <Icon type="upload" /> Choose File
                //   </Button>
                // </Upload>
                <>
                  <Input
                    type="file"
                    className="file-input"
                    onChange={onFileChange}
                    accept=".pdf,.doc,.docx"
                  />
                </>
              )} */}
        </Form.Item>

        {/* <Col md={{ span: 1 }} xs={{ span: 8 }}>
            <div className="c-secondary">Or</div>
          </Col>
          <Col md={{ span: 10 }} xs={{ span: 24 }}>
            <Form.Item className="mb-1">
              {getFieldDecorator("checkResume", {
                //  valuePropName: "unChecked",
                rules: [],
              })(
                <Checkbox
                  className="c-secondary"
                  checked={checkCV}
                  onChange={handleChange}
                >
                  I don't have resume
                </Checkbox>
              )}
            </Form.Item>
          </Col> */}

        <Row>
          <small className="c-secondary">
            Supported Formats: doc,docx,pdf,rtf Max file size: 1 MB
          </small>
        </Row>
        <FormItem className="mb-3">
          {getFieldDecorator("agreement", {
            valuePropName: "checked",
            initialValue: "true",
            rules: [{ validator: checkAgreement }],
          })(
            <div>
              <Checkbox className="c-secondary pr-2" defaultChecked={true}>
                I agree to the{" "}
              </Checkbox>
              <a>
                <small className="c-info" onClick={handleModalView}>
                  Terms and conditions
                </small>
              </a>
              {modalVisibility && (
                <ModalComponent
                  title="Terms and condition"
                  isVisible={modalVisibility as boolean}
                  getValueModal={changeModalView}
                  modalData={TermsConditionData()}
                />
              )}
            </div>
          )}
        </FormItem>
        <Form.Item>
          <Button
            size="large"
            type="danger"
            htmlType="submit"
            className="mb-3 w-50"
            loading={isFileUploading}
            disabled={disableSubmit}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Fragment>
  )
}

export const WrappedSubmitYourCvForm = Form.create({ name: "submit-your-cv" })(
  SubmitYourCvForm
)
