import React, { Fragment } from "react"
import { Row, Col } from "antd"
import SubmitYourCvImage from "../images/submitYourCv.png"
import { WrappedSubmitYourCvForm } from "../components/Forms/SubmitYourCvForm"
import PageContent from "../components/PageContent/PageContent"

const SubmitYourCv = (props) => {
  return (
    <PageContent location={props.location}>
      <Row type="flex" justify="center">
        <h1 className="heading-3 pt-5 mb-6">Submit Your CV</h1>
      </Row>
      <Row
        type="flex"
        justify="space-around"
        align="middle"
        className="px-4 px-md-0"
      >
        <Col sm={24} md={9}>
          <Row>
            <img src={SubmitYourCvImage} className="mw-100 d-lg-block d-none" />
          </Row>
        </Col>
        <Col span={24} lg={10} className="px-md-0 px-4">
          <WrappedSubmitYourCvForm />
        </Col>
      </Row>
    </PageContent>
  )
}

export default SubmitYourCv
