import React, { Fragment } from "react"
import { Row, Col } from "antd"
import aboutUs from "../../images/About_us.png"
import ApplyWithUsCard from "../ApplyWithUsCard/ApplyWithUsCard"
import AboutUsData from "../../../services/homepageData.ts"

const AboutUsDetails = () => {

    const data = AboutUsData.about_us;
    return (
        <Fragment>
            <Row type="flex" justify="center">
                <h1 className="heading-3">About Us</h1>
            </Row>
            <Row className="px-5" gutter={[8, 10]}>
                <Row type="flex" className="pt-4 pb-6" justify="space-between" align="middle">
                    <Col md={24} lg={12}>
                        <p className="font-medium text-align-justify">
                            {data.map(item => {
                                return (item.component_type === "description_text" ? <Row dangerouslySetInnerHTML={{__html: item.text_content}}></Row> : "")
                            })}
                        </p>
                    </Col>
                    <Col md={24} lg={10}>
                        <img src={aboutUs} className="mw-90 d-lg-block d-none" />
                    </Col>
                </Row>
                <Row type="flex" justify="center">
                    <ApplyWithUsCard
                        header="Find your dream job now"
                        content="We recruit over 3500 people per month. It’s your turn now"
                    />
                </Row>
            </Row>
        </Fragment>
    )
}

export default AboutUsDetails