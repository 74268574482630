import React from "react";
import { Icon } from "antd";
import ReactPlayer from "react-player";


const VideoModal = (props: any) => {
  return (
    <div className="modal">
      <div className="close-icon w-100 p-3 text-align-right mt-4">
        <Icon
          onClick={props.onCloseModal}
          className="c-white"
          style={{  fontSize: 30 }}
          type="close-circle"
        />
      </div>
      <div className="d-flex h-100 w-100 justify-content-center">
        <ReactPlayer
          controls
          className="video-player"
          url={props.data.link}
          playing
        />
      </div>
    </div>
  )
}

export default VideoModal;