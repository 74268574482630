export const location = {
  north: "North India",
  south: "South India",
  east: "East India",
  west: "West India",
}

export const urls = {
  clientHub: "",
  associateHub: "",
}
