import React from "react"
import { Row, Col, Button } from "antd"
import { Link } from "gatsby"
import "./HomePageStyle.less"
import HireTalentScreenImg from "../../images/hire-talent.png"
import GoToTopBtn from "./GoToTopBtn"

const HireTalentScreen: React.FC<{ pageData: any }> = ({ pageData }) => {
  return (
    <Row
      type="flex"
      align="middle"
      justify="center"
      className="p-6 p-md-9 p-lg-0"
    >
      <Col md={24} lg={9}>
        {pageData &&
          pageData.map((item: any) => {
            if (item.component_type === "page_header_text") {
              return <h1 className="heading-1 c-white">{item.text.content}</h1>
            }
            if (item.component_type === "sub_header_text") {
              return (
                <h2 className="heading-4 c-white ls-1">{item.text.content}</h2>
              )
            }
            if (item.component_type === "description_text") {
              return (
                <h3 className="c-white fw-600 ls-1" style={{ lineHeight: "2" }}>
                  {item.text_content}
                </h3>
              )
            }
          })}
        <Row className="pt-4">
          <Col md={24} lg={10} className="pr-xs-3 pb-2">
            <Button className="w-100 fw-600 h-6" type="default" size="large">
              <Link to={`/business-enquiry/`} state={{ interested: "" }}>
                Business Enquiry
              </Link>
            </Button>
          </Col>
          <Col md={24} lg={10} className="pr-xs-3 pb-2">
            <Button className="w-100 fw-600 h-6" type="default" size="large">
              <a href="/#staffing">Our Solutions</a>
            </Button>
          </Col>
        </Row>
      </Col>
      <Col span={24} md={10} className="d-none d-lg-block pt-8">
        <img
          src={HireTalentScreenImg}
          alt="hire talent image"
          className="mw-90"
        />
      </Col>
    </Row>
  )
}

export default HireTalentScreen
